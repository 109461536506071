const getDailyPlannedPlays = (campaign) => {
    const start = new Date(campaign.start_date + "T00:00:00").getTime()
    const end = new Date(campaign.end_date + "T23:59:59.999999").getTime()
    const day_ms = 1000*60*60*24
    const diff = end - start
    const days = diff / day_ms
    return campaign.planned_plays / days
}

const getCampaignActiveRangeStatus = (campaign) => {
    const start = new Date(campaign.start_date + "T00:00:00").getTime()
    const end = new Date(campaign.end_date + "T23:59:59.999999").getTime()
    const now = new Date().getTime()
    if ( now < start ) return 'UPCOMING'
    else if ( now >= start && now <= end ) return 'ACTIVE'
    else if ( now > end ) return 'COMPLETE'
    else return 'DRAFT'
}

const getActiveRangePercentComplete = (campaign) => {
    const start = new Date(campaign.start_date + "T00:00:00").getTime()
    const end = new Date(campaign.end_date + "T23:59:59.999999").getTime()
    const now = new Date().getTime()
    const day_ms = 1000*60*60*24
    const rangeDiff = end - start
    const nowDiff = now - start
    const status = getCampaignActiveRangeStatus(campaign)
    if ( status == 'ACTIVE' ) return (nowDiff / rangeDiff) * 100
    else if ( status == 'COMPLETE' ) return 100
}

export { getDailyPlannedPlays, getCampaignActiveRangeStatus, getActiveRangePercentComplete }