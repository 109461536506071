const state = {    
    pagination: {
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        page: 1,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    },
    query: {
        limit: 10,
        offset: 0,
        q: undefined,
        ordering: undefined,
        paging: undefined
    }
}

const getters = {}

const mutations = {
    setPagination (state, payload) {
        state.pagination = payload        
    },
    setPaginationDescending (state, payload) {
        state.pagination.sortDesc = payload        
    },
    setPaginationSortBy (state, payload) {
        state.pagination.sortBy = payload        
    },
    setQueryLimit (state, payload) {
        state.query.limit = payload
    },
    setQueryOffset (state, payload) {
        state.query.offset = payload
    },
    setQuerySearch (state, payload) {
        state.query.q = payload
    },
    setQueryOrdering (state, payload) {
        state.query.ordering = payload
    },
    setQueryPaging (state, payload) {
        state.query.paging = payload
    }
}

const actions = {
    setPagination({ commit }, payload) {
        commit('setPagination', payload)
        if ( payload.itemsPerPage> 0 ) {
            commit('setQueryLimit', payload.itemsPerPage)
            commit('setQueryOffset', (payload.page - 1) * payload.itemsPerPage)
            commit('setQueryPaging', undefined)
        } else {
            commit('setQueryLimit', undefined)
            commit('setQueryOffset', undefined)
            commit('setQueryPaging', 'none')
        }
        if ( payload.sortBy[0] ) {
            if ( payload.sortDesc[0] ) {                
                commit('setQueryOrdering', `-${payload.sortBy[0]}`)  
            } else {
                commit('setQueryOrdering', payload.sortBy[0])
            }
        } else {
            commit('setQueryOrdering', undefined)
        }
    },
    resetPagination({ state, commit, dispatch }) {
        dispatch('setPagination', {
            sortDesc: [],
            page: 1,
            itemsPerPage: state.pagination.itemsPerPage, 
            sortBy: [],
            // totalItems: 0
        })
        commit('setQuerySearch', undefined)
    },
    setOrdering({ commit }, payload) {
        commit('setQueryOrdering', payload)
        if ( payload ) {
            if ( payload.startsWith('-') ) {
                commit('setPaginationDescending', [true])
                commit('setPaginationSortBy', [payload.slice(1)])
            } else {
                commit('setPaginationDescending', [])
                commit('setPaginationSortBy', [payload])
            }
        } else {
            commit('setPaginationDescending', [])
            commit('setPaginationSortBy', [])
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
