// import api from '@/plugins/api'
import router from '@/plugins/router'
import { cloneDeep } from 'lodash'

const state = {
    reportsData: null,
    paginatedReportsData: null,
    reportById: null,
    tempReportId: null
}

const getters = {
    reports ({ reportsData }) {
        return reportsData ? reportsData.results : []
    },
    reportsCount ({ reportsData }) {
        return reportsData ? reportsData.count : 0
    },
    paginatedReports ({ paginatedReportsData }) {
        return paginatedReportsData ? state.paginatedReportsData.results : []
    },
    reportById ({ reportById }) {
        return reportById ? state.reportById : {}
    }
}

const mutations = {
    setReportsData (state, payload) {
        state.reportsData = payload
    },
    setPaginatedReportsData (state, payload) {
        state.paginatedReportsData = payload
    },
    setReportById (state, payload) {
        state.reportById = payload
    },
    setTempReportId (state, payload) {
        state.tempReportId = payload
    }
}

const actions = {
    async getReports({ commit, state }, payload) {
        try {
            commit('setLoading', true, { root: true })
            let data = cloneDeep(state.reportsData)
            if ( data ) {                
                const { limit, offset } = payload.query
                data.results = data.results.slice(offset, offset+limit)
            } else {
                data = {
                    count: 2,
                    results: [
                        {
                            id: 0,
                            campaign: {id: payload.id},
                            range_start: "2019-07-01T00:00:00Z",
                            range_end: "2019-07-07T23:59:59Z",
                            range_start_formatted: "01/07/2019",
                            range_end_formatted: "07/07/2019",
                            report_created: "01/07/2019"
                        },
                        {
                            id: 1,
                            campaign: {id: payload.id},
                            range_start: "2019-07-08T00:00:00Z",
                            range_end: "2019-07-14T23:59:59Z",
                            range_start_formatted: "8/07/2019",
                            range_end_formatted: "14/07/2019",
                            report_created: "01/07/2019"
                        }
                    ]
                }
                commit('setReportsData', data)
                commit('setTempReportId', 2)
            }
            commit('setPaginatedReportsData', data)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getReportById ({ commit, dispatch, getters, rootGetters }, id) {
        try {
            commit('setLoading', true, { root: true })
            const reportData = cloneDeep(getters.reports)
            let report = reportData.find(report => {
                return report.id == id
            })
            const rangeStart = new Date(report.range_start.substring(0, 19))
            const rangeEnd = new Date(report.range_end.substring(0, 19))
            const params = { id: report.campaign.id, query: { limit: 10, offset: 0 } }            
            await dispatch('mediaSchedules/getMediaSchedulesByCampaignId', params, { root: true })
            const mediaSchedulesData = cloneDeep(rootGetters['mediaSchedules/mediaSchedulesByCampaignId'])
            const mediaSchedules = mediaSchedulesData.map((m) => {
                return {
                    id: m.id,
                    media_items: m.media_items,
                    start_datetime: m.start_datetime,
                    end_datetime: m.end_datetime,
                    booking_count: m.booking_count,
                    location_criteria: m.location_criteria
                }
            })
            report.range_start_formatted = `${formatDate(report.range_start.substring(0, 10))}`
            report.range_end_formatted = `${formatDate(report.range_end.substring(0, 10))}`
            report.display_seconds = 10
            report.locations = []
            for ( var m of mediaSchedules ) {
                const start = m.start_datetime ? new Date(m.start_datetime.substring(0, 19)) : null
                if ( start ) {
                    if ( start>= rangeStart && start <= rangeEnd ) {
                        await dispatch('mediaSchedules/getMediaScheduleById', m.id, { root: true })
                        const mById = rootGetters['mediaSchedules/mediaScheduleById']
                        commit('mediaSchedules/setMediaScheduleById', null, { root: true })
                        const media = mById.media_items
                        if ( mById.bookings.length ) {
                            for ( var b of mById.bookings ) {
                                const foundIndex = report.locations.findIndex(loc => { return loc.id === b.location.id })
                                if ( foundIndex !== -1 ) {
                                    const newMedia = report.locations[foundIndex].media_items.concat(media)
                                    report.locations[foundIndex].media_items = newMedia
                                } else {
                                    const durationSecs = 10
                                    const slots = 10
                                    const rand = Math.floor(Math.random()*10)
                                    const plays = 7*24*60*60 / slots / durationSecs //days*hours*minutes*seconds / slots / duration //6048
                                    const actualPlays = plays - rand
                                    const actualPercent = (actualPlays / plays) * 100
                                    const actualPercentA = Math.trunc(actualPercent)
                                    const actualPercentB = Math.round((actualPercent - Math.trunc(actualPercent))*100)
                                    report.locations.push({
                                        id: b.location.id,
                                        name: b.location.name,
                                        media_items: media,
                                        start_date: formatDate(m.start_datetime.substring(0, 10)),
                                        end_date: formatDate(m.end_datetime.substring(0, 10)),
                                        planned_plays: plays,
                                        planned_mins: (plays*10) / 60,
                                        actual_plays: actualPlays,
                                        actual_mins: Math.ceil((actualPlays*10) / 60),
                                        actual_delivery: `${actualPercentA}${actualPercentB ? '.' : ''}${actualPercentB ? actualPercentB : ''}%`,
                                        actual_sov: Math.ceil(actualPercent / 10) + '%',
                                        creative_plays: actualPlays,
                                        creative_mins: Math.floor(actualPlays*durationSecs / 60)
                                    })
                                }
                            }
                        }
                    }
                } else {
                    report.locations.push({
                        id: null,
                        name: null,
                        media_items: null,
                        start_date: null,
                        end_date: null,
                        planned_plays: null,
                        planned_mins: null,
                        actual_plays: null,
                        actual_mins: null,
                        actual_delivery: null,
                        actual_sov: null,
                        creative_plays: null,
                        creative_mins: null
                    })
                }
            }
            commit('setReportById', report)
            commit('bookings/setBookingsByCampaignIdData', null, { root: true })
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async postReport({ commit, state }, payload) {
        try {
            commit('setLoading', true, { root: true })
            let id = state.tempReportId + 1
            let reportsData = cloneDeep(state.reportsData)
            commit('setTempReportId', id)
            const newReport = {
                id: state.tempReportId,
                campaign: {id: payload.campaign_id},
                range_start: `${payload.form_data.start_date}T00:00:00Z`,
                range_end:`${payload.form_data.end_date}T00:00:00Z`,
                range_start_formatted: formatDate(payload.form_data.start_date),
                range_end_formatted: formatDate(payload.form_data.end_date),
                report_created: formatDate(new Date().toISOString().substr(0, 10))
            }
            reportsData.results.push(newReport)
            reportsData.count++                        
            commit('setReportsData', reportsData)
            router.replace({ name: 'CampaignReportsList' })
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async deleteReport({ commit, state }, id) {
        try {
            commit('setLoading', true, { root: true })
            const data = cloneDeep(state.reportsData)
            const reportIndex = data.results.findIndex(i => i.id === id)
            data.results.splice(reportIndex, 1)
            commit('setReportsData', data)
            router.replace({ name: 'CampaignReportsList' })
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

function formatDate (date) {
    const dateSplit = date.split('-')
    return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`
}
