// import api from '@/plugins/api'
import router from '@/plugins/router'
import { cloneDeep } from 'lodash'

const state = {
    reactiveMedia: [
        { 
            id: 0, 
            name: 'Reactive Weather Item',
            reactive_media_items: [
                {
                    media_item: {
                        id: 0,
                        thumbnail_url: "https://emmet-staging-thumbnails.s3.amazonaws.com/dog_432x720.jpg.jpg?AWSAccessKeyId=AKIAI2KMCEMW4OJEV3XQ&Signature=zS7vmfajf0toDBbcKQ6K75%2FrEsU%3D&Expires=1591167277",
                        name: 'doggy.jpg',
                    },
                    rules: [
                        {
                            attribute: 'Sky Conditions',
                            condition: 'Includes',
                            values: ['Sunny']
                        }
                    ]
                }
            ],
            default_media_item: {
                id: 0,
                thumbnail_url: "https://emmet-staging-thumbnails.s3.amazonaws.com/cats_576x960.jpg.jpg?AWSAccessKeyId=AKIAI2KMCEMW4OJEV3XQ&Signature=z0ACt5dfDNy9n%2FJUGXgLvY9NmTE%3D&Expires=1591167343",
                name: 'kitties.jpg'
            }
        },
        { 
            id: 1, 
            name: 'Temperature Creative',
            reactive_media_items: [
                {
                    media_item: {
                        id: 0,
                        thumbnail_url: "https://emmet-staging-thumbnails.s3.amazonaws.com/fish.jpg.jpg?AWSAccessKeyId=AKIAI2KMCEMW4OJEV3XQ&Signature=Ym4PsbC9E%2FD1NFAARkm9fhp5y5w%3D&Expires=1590815670",
                        name: 'goldfish.jpg'
                    },
                    rules: [
                        {
                            attribute: 'Temperature',
                            condition: '>=',
                            values: ['18']
                        },
                        {
                            attribute: 'Temperature',
                            condition: '<=',
                            values: [30]
                        }
                    ]
                }
            ],
            default_media_item: {
                id: 0,
                thumbnail_url: "https://emmet-staging-thumbnails.s3.amazonaws.com/cats_576x960.jpg.jpg?AWSAccessKeyId=AKIAI2KMCEMW4OJEV3XQ&Signature=z0ACt5dfDNy9n%2FJUGXgLvY9NmTE%3D&Expires=1591167343",
                name: 'kitties.jpg'
            }
        }
    ],
    tempReactiveMediaId: 2,
    reactiveMediaById: null,
    ruleAttributes: [
        {
            text: 'Temperature',
            conditions: ['<', '<=', '=', '>=', '>'],
            values: null

        },
        {
            text: 'Sky Conditions', 
            conditions: ['Includes', 'Excludes'],
            values: ['Sunny', 'Overcast', 'Rainy']
        },
        {
            text: 'Closest Viewer Demographic', 
            conditions: ['Includes', 'Excludes'],
            values: ['Child Male', 'Young Male', 'Adult Male', 'Senior Male', 'Child Female', 'Young Female', 'Adult Female', 'Senior Female']
        },
        {
            text: 'Predominant Viewer Demographic', 
            conditions: ['Includes', 'Excludes'],
            values: ['Child Male', 'Young Male', 'Adult Male', 'Senior Male', 'Child Female', 'Young Female', 'Adult Female', 'Senior Female']
        },
        {
            text: 'Shirt Colour', 
            conditions: ['Includes', 'Excludes'],
            values: ['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Purple', 'Grey', 'White', 'Black', 'Beige, Definitely Beige']
        }
    ],
    headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Reactive Media Items', sortable: false, class: '' },
        { text: 'Default Media Item', sortable: false, class: '' }
    ]
}

const getters = {
    reactiveMedia ({ reactiveMedia }) {
        return reactiveMedia ? reactiveMedia : []
    },
    reactiveMediaById ({ reactiveMediaById }) {
        return reactiveMediaById ? reactiveMediaById : {}
    },
    ruleAttributes ({ ruleAttributes }) {
        const sortThis = cloneDeep(ruleAttributes)
        return sortThis.sort((a, b) => {
            if ( a.text.toLowerCase() < b.text.toLowerCase() ) return -1
            else if ( a.text.toLowerCase()> b.text.toLowerCase() ) return 1
            else return 0
        })
    },
}

const mutations = {
    setReactiveMedia (state, payload) {
        state.reactiveMedia = payload
    },
    setReactiveMediaById (state, payload) {
        state.reactiveMediaById = payload
    },
    putReactiveMediaById (state, payload) {
        state.reactiveMedia[payload.index] = payload.data
    },
    incrementTempReactiveMediaId (state) {
        state.tempReactiveMediaId += 1
    },
    postReactiveMediaById (state, payload) {
        state.reactiveMedia.push(Object.assign({}, { id: state.tempReactiveMediaId }, payload))
        router.replace({ name: 'ReactiveMediaById', params: { id: state.tempReactiveMediaId }})
    },
    deleteReactiveMediaById (state, index) {
        state.reactiveMedia.splice(index, 1)
    }
}

const actions = {
    getReactiveMediaById({ state, commit }, id) {
        let payload
        if ( id || id == 0 ) {
            const index = state.reactiveMedia.findIndex(x => x.id == id)
            payload = cloneDeep(state.reactiveMedia[index])
        } else {
            payload = null
        }
        commit('setLoading', true, { root: true })
        commit('setReactiveMediaById', payload)
        setTimeout(() => {
            commit('setLoading', false, { root: true })
        }, 1000)
    },
    putReactiveMediaById({ commit }, payload) {
        const index = state.reactiveMedia.findIndex(x => x.id == payload.id)
        commit('setLoading', true, { root: true })
        setTimeout(() => {
            commit('putReactiveMediaById', { index: index, data: payload.formData })
            commit('setLoading', false, { root: true })
        }, 1000)
    },
    postReactiveMediaById({ commit }, payload) {
        commit('incrementTempReactiveMediaId')
        commit('setLoading', true, { root: true })
        setTimeout(() => {
            commit('postReactiveMediaById', payload)
            commit('setLoading', false, { root: true })
        }, 2000)
    },
    deleteReactiveMediaById({ state, commit }, id) {        
        const index = state.reactiveMedia.findIndex(x => x.id == id)
        commit('deleteReactiveMediaById', index)
        commit('setLoading', true, { root: true })
        setTimeout(() => {
            router.replace({ name: 'ReactiveMediaList' })
            commit('setLoading', false, { root: true })
        }, 1000)
    },




    // setPagination({ commit }, payload) {
    //     commit('setPagination', payload)
    //     if ( payload.itemsPerPage> 0 ) {
    //         commit('setQueryLimit', payload.itemsPerPage)
    //         commit('setQueryOffset', (payload.page - 1) * payload.itemsPerPage)
    //         commit('setQueryPaging', undefined)
    //     } else {
    //         commit('setQueryLimit', undefined)
    //         commit('setQueryOffset', undefined)
    //         commit('setQueryPaging', 'none')
    //     }
    //     if ( payload.sortBy[0] ) {
    //         if ( payload.sortDesc[0] ) {                
    //             commit('setQueryOrdering', `-${payload.sortBy[0]}`)  
    //         } else {
    //             commit('setQueryOrdering', payload.sortBy[0])
    //         }
    //     } else {
    //         commit('setQueryOrdering', undefined)
    //     }
    // },
    // resetPagination({ state, commit, dispatch }) {
    //     dispatch('setPagination', {
    //         sortDesc: [],
    //         page: 1,
    //         itemsPerPage: state.pagination.itemsPerPage, 
    //         sortBy: [],
    //         // totalItems: 0
    //     })
    //     commit('setQuerySearch', undefined)
    // },
    // setOrdering({ commit }, payload) {
    //     commit('setQueryOrdering', payload)
    //     if ( payload ) {
    //         if ( payload.startsWith('-') ) {
    //             commit('setPaginationDescending', [true])
    //             commit('setPaginationSortBy', [payload.slice(1)])
    //         } else {
    //             commit('setPaginationDescending', [])
    //             commit('setPaginationSortBy', [payload])
    //         }
    //     } else {
    //         commit('setPaginationDescending', [])
    //         commit('setPaginationSortBy', [])
    //     }
    // },

    
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
