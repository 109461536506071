import api from '@/plugins/api'
import router from '@/plugins/router'
import names from '@/configs/app_names'
import { getCampaignActiveRangeStatus } from '@/utils'

const state = {
    campaigns: null,
    campaignById: null,
    filters: {
        // status: [
        //     { text: 'Draft', value: 'DRAFT' },
        //     { text: 'Upcoming', value: 'UPCOMING' },
        //     { text: 'Active', value: 'ACTIVE' },
        //     { text: 'Expired', value: 'EXPIRED' },
        //     { text: 'Cancelled', value: 'CANCELLED' }
        // ]
    },            
    ordering: [
        { text: 'Updated (First)', value: '-updated' },
        { text: 'Updated (Last)', value: 'updated' },
        { text: 'Name (A-Z)', value: 'name' },
        { text: 'Name (Z-A)', value: '-name' },
        { text: 'Start Date (asc)', value: 'start_date' },
        { text: 'Start Date (desc)', value: '-start_date' },
        { text: 'End Date (asc)', value: 'end_date' },
        { text: 'End Date (desc)', value: '-end_date' },
        { text: 'Created (First)', value: 'created' },
        { text: 'Created (Last)', value: '-created' }
    ],
    headers: [
        { text: 'Name', align: 'left', value: 'name' },
        // { text: 'Status', align: 'left', sortable: false, class: 'hidden-sm-and-down' },
        { text: 'Category', align: 'left', sortable: false, class: 'hidden-xs-only' },
        { text: 'Date Range', align: 'left', sortable: false },
        // { text: 'Start', align: 'left', value: 'start_date', class: 'hidden-xs-only' },
        // { text: 'End', align: 'left', value: 'end_date', class: 'hidden-xs-only' },
        { text: 'Creative Coverage', align: 'left', sortable: false, class: 'hidden-sm-and-down'  },
        { text: 'Delivery (plays)', align: 'left', sortable: false, class: 'hidden-sm-and-down'  },
        { text: names.media.label.singular, align: 'left', sortable: false, class: 'hidden-sm-and-down pr-0'  },
        // { text: '', align: 'left', sortable: false, class: '' }
        { text: '', value: 'data-table-expand' }

    ],
    pagination: {
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        page: 1,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    },
    query: {
        limit: 10,
        offset: 0,
        q: undefined,
        ordering: undefined,
        paging: undefined,
        category: undefined
    },
    campaignsForFilter: null
}

const getters = {
    campaigns ({ campaigns }) {
        return campaigns ? campaigns.data.results : []
    },
    campaignsCount ({ campaigns }) {
        return campaigns ? campaigns.data.count : 0
    },
    campaignById ({ campaignById }) {
        return campaignById ? campaignById.data : {}
    },
    campaignsForFilter ({ campaignsForFilter }) {
        return campaignsForFilter ? campaignsForFilter.data.results : []
    }
}

const mutations = {
    setCampaigns (state, payload) {
        if ( payload ) {
            payload.data.results.forEach(x => {            
                x.status = getCampaignActiveRangeStatus(x)
            })
        }
        state.campaigns = payload
    },
    setCampaignById (state, payload) {
        state.campaignById = payload
    },
    setPagination (state, payload) {
        state.pagination = payload
    },
    // setPaginationTotalItems (state, payload) {
    //     state.pagination.totalItems = payload
    // },
    setPaginationDescending (state, payload) {
        // state.pagination.sortDesc = payload
        state.pagination.sortDesc = payload
    },
    setPaginationSortBy (state, payload) {
        state.pagination.sortBy = payload
    },
    // setQuery (state, payload) {
    //     state.query = payload
    // },
    setQueryLimit (state, payload) {
        state.query.limit = payload
    },
    setQueryOffset (state, payload) {
        state.query.offset = payload
    },
    setQuerySearch (state, payload) {
        state.query.q = payload
    },
    setQueryOrdering (state, payload) {
        state.query.ordering = payload
    },
    setQueryPaging (state, payload) {
        state.query.paging = payload
    },
    setQueryCategory (state, payload) {
        state.query.category = payload
    }, 
    setCampaignsForFilter (state, payload) {
        state.campaignsForFilter = payload
    }
}

const actions = {
    async getCampaigns({ commit, rootState }) {
        try {
            commit('setLoading', true, { root: true })
            commit('setCampaigns', await api.get('/campaigns', {
                params: rootState.route.query
            }))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getCampaignsWithInternalQuery({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            commit('setCampaigns', await api.get('/campaigns', payload))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getCampaignsForFilter({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            commit('setCampaignsForFilter', await api.get('/campaigns', payload))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    setPagination({ commit }, payload) {
        commit('setPagination', payload)
        if ( payload.itemsPerPage> 0 ) {
            commit('setQueryLimit', payload.itemsPerPage)
            commit('setQueryOffset', (payload.page - 1) * payload.itemsPerPage)
            commit('setQueryPaging', undefined)
        } else {
            commit('setQueryLimit', undefined)
            commit('setQueryOffset', undefined)
            commit('setQueryPaging', 'none')
        }
        if ( payload.sortBy[0] ) {
            if ( payload.sortDesc[0] ) {
                commit('setQueryOrdering', `-${payload.sortBy[0]}`)
            } else {
                commit('setQueryOrdering', payload.sortBy[0])
            }
        } else {
            commit('setQueryOrdering', undefined)
        }
    },
    resetPagination({ state, commit, dispatch }) {
        dispatch('setPagination', {
            sortDesc: [],
            page: 1,
            itemsPerPage: state.pagination.itemsPerPage,
            sortBy: [],
            // totalItems: 0
        })
        commit('setQuerySearch', undefined)
    },
    setOrdering({ commit }, payload) {
        commit('setQueryOrdering', payload)
        if ( payload ) {
            if ( payload.startsWith('-') ) {
                commit('setPaginationDescending', [true])
                commit('setPaginationSortBy', [payload.slice(1)])
            } else {
                commit('setPaginationDescending', [])
                commit('setPaginationSortBy', [payload])
            }
        } else {
            commit('setPaginationDescending', [])
            commit('setPaginationSortBy', [])
        }
    },
    async getCampaignById({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            commit('setCampaignById', await api.get(`/campaigns/${id}`))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async postCampaign({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            const res = await api.post('/campaigns', payload)
            router.replace(`/campaigns/${res.data.id}/bookings`)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async putCampaignById({ commit, dispatch }, payload) {
        try {
            commit('setLoading', true, { root: true })
            const res = await api.put(`/campaigns/${payload.id}`, payload.formData)
            commit('setCampaigns', await api.get('/campaigns'))
            dispatch('getCampaignById', (res.data.id))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async deleteCampaignById({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            await api.delete(`/campaigns/${id}`)
            // router.replace('/campaigns')
            // router.replace('/campaigns').catch(err => {})
            router.replace('/campaigns', () => {})
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
