import api from '@/plugins/api'
import router from '@/plugins/router'
import names from '@/configs/app_names'

import { normalize, schema } from 'normalizr'
const attributeValueSchema = new schema.Entity('attributeValues')
const locationSchema = new schema.Entity('locations', {  
    site: { attribute_values: [ attributeValueSchema ] },
    attribute_values: [ attributeValueSchema ]
})

const state = {
    locationsDataNEW: null,
    locationsSearchDataNEW: null,
    locationsIdDataNEW: null,
    locations: null,
    locationById: null,
    ordering: [
        { text: 'Name (A-Z)', value: 'name' },
        { text: 'Name (Z-A)', value: '-name' },
        { text: 'Content Spec (A-Z)', value: 'content_specification__name' },
        { text: 'Content Spec (Z-A)', value: '-content_specification__name' }
    ],
    headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Site', align: 'left', sortable: false, class: 'hidden-xs-only' },
        // { text: 'Panel', align: 'left', sortable: false, class: 'hidden-sm-and-down' },
        { text: 'Dimensions', align: 'left', sortable: false, class: 'hidden-sm-and-down' },
        { text: 'Content Spec', align: 'left', value: 'content_specification__name', class: 'hidden-sm-and-down' },
        { text: 'Loop Policy', align: 'left', sortable: false, class: 'hidden-sm-and-down' },
        { text: 'Operating Hours', align: 'left', sortable: false, class: 'hidden-sm-and-down' },
        { text: '', align: 'left', sortable: false, class: 'hidden-md-and-up' }
    ],
    pagination: {
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        page: 1,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    },
    query: {
        limit: 10,
        offset: 0,
        q: undefined,
        ordering: undefined,
        paging: undefined,
        site: undefined,
        playlist_policy: undefined,
        filters: undefined,
        content_specification: undefined,
        operating_hours_policy: undefined,
        pixel_width: undefined,
        pixel_height: undefined
    },
    queryFilterKeys: [
        'site',
        'playlist_policy',
        'filters',
        'content_specification',
        'operating_hours_policy',
        'pixel_width',
        'pixel_height'
    ],
    // locationsSearchData: null,
    locationsSearch: null
}

const getters = {
    locationsSearchResultsNEW: ({ locationsSearchDataNEW }) => locationsSearchDataNEW ? 
        locationsSearchDataNEW.result.results.map(id => locationsSearchDataNEW.entities.locations[id]) : [],
    locationsSearchCountNEW: ({ locationsSearchDataNEW }) => locationsSearchDataNEW ? locationsSearchDataNEW.result.count : 0,
    locations ({ locations }) {
        return locations ? locations.data.results : []
    },
    locationsCount ({ locations }) {
        return locations ? locations.data.count : 0
    },
    locationById ({ locationById }) {
        return locationById ? locationById.data : {}
    },
    // locationsSearchResults ({ locationsSearchData }) {
    //     return locationsSearchData ? locationsSearchData.results : []
    // },
    // locationsSearchCount ({ locationsSearchData }) {
    //     return locationsSearchData ? locationsSearchData.count : 0
    // },
    queryFiltersActive ({ query, queryFilterKeys }) {
        return queryFilterKeys.some(x => query[x])
    },
    locationsSearch ({ locationsSearch }) {
        return locationsSearch ? locationsSearch.data.results : []
    },
    locationsSearchCount ({ locationsSearch }) {
        return locationsSearch ? locationsSearch.data.count : 0
    }
}

const mutations = {
    setLocationsSearchDataNEW (state, payload) {
        state.locationsSearchDataNEW = payload ? normalize(payload, { results: [ locationSchema ] }) : null
    },
    setLocations (state, payload) {
        state.locations = payload        
    },
    setLocationById (state, payload) {
        state.locationById = payload
    },
    setPagination (state, payload) {
        state.pagination = payload        
    },
    setPaginationDescending (state, payload) {
        state.pagination.sortDesc = payload        
    },
    setPaginationSortBy (state, payload) {
        state.pagination.sortBy = payload        
    },
    setQueryLimit (state, payload) {
        state.query.limit = payload
    },
    setQueryOffset (state, payload) {
        state.query.offset = payload
    },
    setQuerySearch (state, payload) {
        state.query.q = payload
    },
    setQueryOrdering (state, payload) {
        state.query.ordering = payload
    },
    setQueryPaging (state, payload) {
        state.query.paging = payload
    },
    setQueryProperty (state, { property, value }) {
        state.query[property] = value
    },
    // setLocationSearchData (state, payload) {
    //     state.locationsSearchData = payload
    // },
    setLocationsSearch (state, payload) {
        state.locationsSearch = payload
    }
}

const actions = {
    async getLocationsSearchNEW({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            commit('setLocationsSearchDataNEW', await api.get(`${names.locations.api}/search`, payload).then(response => response.data))            
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getLocationsSearchLocalNEW({ commit }, payload) {
        try {
            return await api.get(`${names.locations.api}/search`, payload).then(response => response.data.results)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
            throw error
        }
    },
    async getLocations({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            commit('setLocations', await api.get(names.locations.api, payload))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getLocationsWithInternalQuery({ commit }, query) { // genericise this.
        try {
            commit('setLoading', true, { root: true })
            commit('setLocations', await api.get(names.locations.api, { params: query }))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    setPagination({ commit }, payload) {
        commit('setPagination', payload)
        if ( payload.itemsPerPage > 0 ) {
            commit('setQueryLimit', payload.itemsPerPage)
            commit('setQueryOffset', (payload.page - 1) * payload.itemsPerPage)
            commit('setQueryPaging', undefined)
        } else {
            commit('setQueryLimit', undefined)
            commit('setQueryOffset', undefined)
            commit('setQueryPaging', 'none')
        }
        if ( payload.sortBy[0] ) {
            if ( payload.sortDesc[0] ) {                
                commit('setQueryOrdering', `-${payload.sortBy[0]}`)  
            } else {
                commit('setQueryOrdering', payload.sortBy[0])
            }
        } else {
            commit('setQueryOrdering', undefined)
        }
    },
    resetPagination({ state, commit, dispatch }) {
        dispatch('setPagination', {
            sortDesc: [],
            page: 1,
            itemsPerPage: state.pagination.itemsPerPage, 
            sortBy: [],
            // totalItems: 0
        })
        commit('setQuerySearch', undefined)
        state.queryFilterKeys.forEach( x => {
            commit('setQueryProperty', { property: x, value: undefined })
        })
    },
    setOrdering({ commit }, payload) {
        commit('setQueryOrdering', payload)
        if ( payload ) {
            if ( payload.startsWith('-') ) {
                commit('setPaginationDescending', [true])
                commit('setPaginationSortBy', [payload.slice(1)])
            } else {
                commit('setPaginationDescending', [])
                commit('setPaginationSortBy', [payload])
            }
        } else {
            commit('setPaginationDescending', [])
            commit('setPaginationSortBy', [])
        }
    },
    async getLocalLocations({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            return await api.get(names.locations.api, payload)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getLocationById({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            commit('setLocationById', await api.get(`${names.locations.api}/${id}`))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getLocationsBySearch({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            // const res = await api.get(`${names.locations.api}/search?filters=${encodeURIComponent(JSON.stringify(payload.body))}`, { params: payload.query })
            // const res = await api.get(`${names.locations.api}/search`, payload)
            // commit('setLocationSearchData', res.data) //if you commit res directly you get that deep object mutation issue.           
            commit('setLocationsSearch', await api.get(`${names.locations.api}/search`, payload))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async postLocation({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            const res = await api.post(names.locations.api, payload)
            router.replace({ name: names.locations.route + 'Instance', params: { id: res.data.id } })
            
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async putLocationById({ commit, dispatch }, payload) {
        try {
            commit('setLoading', true, { root: true })
            const res = await api.put(`${names.locations.api}/${payload.id}`, payload.formData)
            commit('setLocations', await api.get(names.locations.api))
            dispatch('getLocationById', (res.data.id))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async deleteLocationById({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            await api.delete(`${names.locations.api}/${id}`)
            router.replace({ name: names.locations.route })
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
