import api from '@/plugins/api'
import router from '@/plugins/router'

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

// const waitFor = (ms) => new Promise(r => setTimeout(r, ms)) //for testing fake api delay

const state = {
    mediaSchedules: null,
    mediaSchedulesByCampaignIdData: null,
    mediaScheduleById: null
}

const getters = {
    mediaSchedules ({ mediaSchedules }) {
        return mediaSchedules ? mediaSchedules.data.results : []
    },
    mediaSchedulesByCampaignId ({ mediaSchedulesByCampaignIdData }) {
        return mediaSchedulesByCampaignIdData ? mediaSchedulesByCampaignIdData.results : []
    },
    mediaSchedulesByCampaignIdCount ({ mediaSchedulesByCampaignIdData }) {
        return mediaSchedulesByCampaignIdData ? mediaSchedulesByCampaignIdData.count : 0
    },
    mediaScheduleById ({ mediaScheduleById }) {
        return mediaScheduleById ? mediaScheduleById.data : {}
    }
}

const mutations = {
    setMediaSchedules (state, payload) {
        state.mediaSchedulesByCampaignIdData = payload
    },
    setMediaSchedulesByCampaignIdData (state, payload) {
        state.mediaSchedulesByCampaignIdData = payload
    },
    resetMediaSchedulesByCampaignId (state) {
        state.mediaSchedulesByCampaignIdData = null
    },
    setMediaScheduleById (state, payload) {
        state.mediaScheduleById = payload
    }
}

const actions = {
    async getMediaSchedules({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            commit('setMediaSchedules', await api.get(`/media_instructions`, payload))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getMediaSchedulesByCampaignId({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            const res = await api.get(`/media_instructions?campaign=${payload.id}`, { params: payload.query })
            commit('setMediaSchedulesByCampaignIdData', res.data)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getMediaScheduleById({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            commit('setMediaScheduleById', await api.get(`/media_instructions/${id}`))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async postMediaSchedule({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            await api.post('/media_instructions', payload)
            router.replace({ name: 'CampaignMediaSchedulesList' })
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async postMediaSchedules({ commit, dispatch, rootState }, payload) {
        await asyncForEach(payload.selectedMediaSchedules, async (mediaInstruction) => {
            await asyncForEach(Array(payload.count).fill('x'), async x => {
                try {
                    commit('setLoading', true, { root: true })
                    await api.post('/media_instructions', mediaInstruction)
                } catch(error) {
                    commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
                } finally {
                    commit('setLoading', false, { root: true })
                }
            })
        })
        dispatch('getMediaSchedulesByCampaignId', {
            id: rootState.route.params.id,
            query: payload.query
        })
    },
    async putMediaSchedule({ commit, rootState }, payload) {
        try {
            commit('setLoading', true, { root: true })
            await api.put(`/media_instructions/${payload.id}`, payload.formData)
            // router.replace({ name: 'CampaignMediaSchedulesById', params: { msid: rootState.route.params.msid } })
            // commit('setSnackbar', { type: 'success', msg: 'Creative Schedule Updated' }, { root: true })
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
            router.replace({ name: 'CampaignMediaSchedulesList' })
        }
    },
    async deleteMediaSchedule({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            await api.delete(`/media_instructions/${id}`)
            router.replace({ name: 'CampaignMediaSchedulesList' })
            // commit('setSnackbar', { type: 'success', msg: 'Creative Schedule Deleted' }, { root: true })
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async deleteMediaSchedules({ commit, dispatch, rootState }, payload) {
        await asyncForEach(payload.selectedMediaSchedules, async (mediaInstruction) => {
            try {
                commit('setLoading', true, { root: true })
                await api.delete(`/media_instructions/${mediaInstruction.id}`)
            } catch(error) {
                commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
            } finally {
                commit('setLoading', false, { root: true })
            }
        })
        dispatch('getMediaSchedulesByCampaignId', {
            id: rootState.route.params.id,
            query: payload.query
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
