export default {
    media: {
        api: '/media',
        label: {
            group: null,
            singular: 'Creative',
            plural: 'Creatives',
        },
        routeGroup: null,
        pathGroup: null,
        route: 'Creatives',
        path: '/creatives'
    },
    locations: {
        api: '/locations',
        label: {
            group: 'Inventory',
            singular: 'Location',
            plural: 'Locations',
        },
        routeGroup: 'Inventory',
        pathGroup: '/inventory',
        route: 'Locations',
        path: '/locations'
    }
}