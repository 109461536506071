import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import router from '@/plugins/router'
import groups from '@/configs/user_groups'

// Amplify.Logger.LOG_LEVEL = 'DEBUG'

const state = {
    user: null
}

const getters = {
    username ({ user }) {
        return user ? user.username : null
    },
    // jwtTokenOld (state) {
    //     return state.user.signInUserSession ? state.user.signInUserSession.idToken.jwtToken : null
    // },
    jwtToken ({ user }) {
        return user ? user.signInUserSession.getIdToken().getJwtToken() : null
    },
    userGroup ({ user }) {
        const payload = user ? user.signInUserSession.getIdToken().payload : {}
        return "cognito:groups" in payload ? payload["cognito:groups"][0] : null
    },
    userGroupExists (_, { userGroup }) {
        return userGroup in groups
    },
    userGroupAllowedRoutes (_, { userGroup, userGroupExists }) {
        return userGroupExists ? groups[userGroup].allowedRoutes : []
    },
    userGroupAllowedWriteAccess (_, { userGroup, userGroupExists }) {
        return userGroupExists ? groups[userGroup].allowedWriteAccess : []
    },
    // userRoles ({ user }) {
    //     return user ? user.signInUserSession.getIdToken().payload["cognito:roles"] : []
    // }
}

const mutations = {
    setUser (state, payload) {
        state.user = payload
    },
}

const actions = {
    // async getCurrentSession ({ commit }) {
    //     try {
    //         commit('setLoading', true, { root: true })
    //         const res = await Auth.currentSession()
    //     } catch(error) {
    //         console.error('cognito/getCurrentSession', error)
    //     } finally {
    //         commit('setLoading', false, { root: true })
    //     }        
    // },
    // async getCurrentAuthenticatedUser ({ commit }) {
    //     try {
    //         commit('setLoading', true, { root: true })
    //         const res = await Auth.currentAuthenticatedUser({
    //                 bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    //         })
    //         commit('setUser', res)
    //         return true
    //     } catch(error) {
    //         commit('setUser', null)
    //         return false
    //     } finally {
    //         commit('setLoading', false, { root: true })
    //     }        
    // },
    async getCurrentAuthenticatedUser ({ commit }) {
        try {
            // commit('setLoading', true, { root: true })
            const res = await Auth.currentAuthenticatedUser({
                bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            })
            commit('setUser', res)
        } catch(error) {
            commit('setUser', null)
            console.log('Error:', error)
        } finally {
            // commit('setLoading', false, { root: true })
        }        
    },
    // async forgotPassword ({ commit }, username) {
    //     try {
    //         commit('setLoading', true, { root: true })
    //         const res = await Auth.forgotPassword(username)
    //         commit('setSnackbar', { type: 'success', msg: `Did forgot thing ${username}` }, { root: true })
    //     } catch(error) {
    //         commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
    //     } finally {
    //         commit('setLoading', false, { root: true })
    //     }
    // },
    async signOut ({ commit }) {
        try {
            commit('setLoading', true, { root: true })
            await Auth.signOut()
            router.replace('/')
            commit('setSnackbar', { type: 'success', msg: `Signed out.` }, { root: true })
        } catch (error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    init ({ dispatch }) {
        dispatch('getCurrentAuthenticatedUser')
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
