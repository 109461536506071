import names from '@/configs/app_names'
import groups from '@/configs/user_groups'

export default {
    namespaced: true,
    state: {
        names: names,
        groups: groups,
        sideMenuItems: [
            {
                name: 'Campaigns',
                icon: 'mdi-briefcase-outline',
                route: 'CampaignsGroup',
                children: [
                    { 
                        name: 'Campaigns', 
                        icon: 'mdi-briefcase',
                        route: 'Campaigns'
                        // path: '/campaigns/list',
                    },
                    { 
                        name: 'Bookings', 
                        icon: 'mdi-calendar-check',
                        route: 'Bookings'
                        // path: '/campaigns/bookings',
                    },
                    { 
                        name: 'Categories', 
                        icon: 'mdi-shape',
                        route: 'CampaignCategories'
                        // path: '/campaigns/categories',
                    }
                ]
            },                
            { 
                name: 'Media', 
                icon: 'mdi-play-box',
                route: 'MediaGroup',
                children: [
                    { 
                        name: 'Creatives', 
                        icon: 'mdi-play-box',
                        route: 'Creatives'
                        // path: '/creatives', 
                    },
                    { 
                        name: 'Dynamic Media', 
                        icon: 'mdi-play-network-outline',
                        route: 'DynamicMediaList',
                        // path: '/dynamic_media', 
                        experimental: true 
                    }
                ]
            },
            { 
                name: 'Loops', 
                icon: 'mdi-repeat',
                route: 'LoopsGroup',
                children: [
                    { 
                        name: 'Loops', 
                        icon: 'mdi-repeat',
                        route: 'Loops',
                        // path: '/loops/list',
                    },
                    { 
                        name: 'Loop Policies', 
                        icon: 'mdi-police-badge',
                        route: 'LoopPolicies',
                        // path: '/loops/loop_policies', 
                    }
                ]
            },
            { 
                name: 'Locations', 
                icon: 'mdi-map-marker',
                route: 'LocationsGroup',
                children: [
                    { 
                        name: 'Locations', 
                        icon: 'mdi-map-marker',
                        route: 'Locations'
                        // path: '/locations/list',
                    },
                    { 
                        name: 'Attributes', 
                        icon: 'mdi-star-circle',
                        route: 'Attributes'
                        // path: '/locations/attributes', 
                    },
                    { 
                        name: 'Content Specs', 
                        icon: 'mdi-details',
                        route: 'ContentSpecifications'
                        // path: '/locations/content_specifications', 
                    },
                    { 
                        name: 'Operating Hours', 
                        icon: 'mdi-clock-time-three',
                        route: 'OperatingHoursPolicies'
                        // path: '/locations/operating_hours_policies', 
                    }
                ]
            },
            { 
                name: 'Sites', 
                icon: 'mdi-crosshairs',
                route: 'SitesGroup',
                children: [
                    {
                        name: 'Sites', 
                        icon: 'mdi-crosshairs',
                        route: 'Sites'
                        // path: '/sites',
                    }
                ]
            },
        ],
        menuItems: [
            {
                label: 'Campaigns',
                icon: 'mdi-briefcase-outline',
                route: 'CampaignsGroup',
                pathGroup: '/campaigns',
                groupExpand: null,
                subItems: [
                    { 
                        label: 'Campaigns', 
                        route: 'Campaigns',
                        icon: 'mdi-circle-medium'
                    },
                    { 
                        label: 'Bookings', 
                        route: 'Bookings',
                        icon: 'mdi-circle-medium' 
                    },
                    { 
                        label: 'Categories', 
                        route: 'CampaignCategories',
                        icon: 'mdi-circle-medium' 
                    }
                ]
            },
            { 
                // MEDIA 
                label: names.media.label.plural,
                icon: 'mdi-play-box-outline',
                route: names.media.route
            },
            { 
                label: 'Reactive Media', 
                icon: 'mdi-play-network-outline', 
                route: 'ReactiveMediaList', 
                experimental: true 
            },
            { 
                label: 'Loops', 
                icon: 'mdi-repeat',
                route: 'Loops', 
                pathGroup: '/loops',
                groupExpand: null,
                subItems: [
                    { 
                        label: 'Loops', 
                        icon: 'mdi-circle-medium',
                        route: 'LoopsList'
                    },
                    { 
                        label: 'Loop Policies', 
                        icon: 'mdi-circle-medium',
                        route: 'LoopPolicies'
                    }
                ]
            },
            { 
                label: names.locations.label.group,
                icon: 'mdi-view-grid-plus-outline',
                route: names.locations.routeGroup,
                pathGroup: names.locations.pathGroup,
                groupExpand: null,
                subItems: [
                    { 
                        label: names.locations.label.plural, 
                        icon: 'mdi-circle-medium',
                        // route: 'LocationsList'
                        route: names.locations.route
                    },
                    { 
                        label: 'Attributes', 
                        icon: 'mdi-circle-medium',
                        route: 'Attributes'
                    },
                    { 
                        label: 'Content Specs', 
                        icon: 'mdi-circle-medium',
                        route: 'ContentSpecifications'
                    },
                    { 
                        label: 'Operating Hours', 
                        icon: 'mdi-circle-medium',
                        route: 'OperatingHoursPolicies'
                    }
                ]
            },
            { 
                label: 'Sites', 
                icon: 'mdi-map-marker-outline',
                route: 'Sites'
            }
        ],
        appLoading: []
    },
    getters: {
        // sideMenuItems: ({ sideMenuItems }) => (sideMenuItems),
        sideMenuItems ({ sideMenuItems }, _, { experimentalFeatures }) {
            let arr = []
            for (const item of sideMenuItems) {
                let x = { ...item }
                x.children = experimentalFeatures ? item.children : item.children.filter(child => !child.experimental)
                arr.push(x)
            }
            return arr
        },
        menuItems ({ menuItems }, _, { experimentalFeatures }) {
            return experimentalFeatures ? menuItems : menuItems.filter(item => !item.experimental)
        },
        appLoading: ({ appLoading }) => ( Boolean(appLoading.length) )
    },
    mutations: {
        setMenuItemGroupExpand ({ menuItems }, payload) {
            menuItems.find(x => x.label === payload.label).groupExpand = payload.value
        },
        setAppLoading ({ appLoading }, payload) {
            if ( payload ) appLoading.push(payload)
            else appLoading.pop()
        },
    },
    actions: {}
}