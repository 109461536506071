import api from '@/plugins/api'
import router from '@/plugins/router'
import names from '@/configs/app_names'

import { normalize, schema } from 'normalizr'
const mediaItemSchema = new schema.Entity('mediaItems')
const mediaInstructionSchema = new schema.Entity('mediaInstructions', {  media_items: [ mediaItemSchema ] })
const bookingSchema = new schema.Entity('bookings', {  media_instructions: [ mediaInstructionSchema ] })

const Progress = (remaining, total) => Math.round((total - remaining) / total * 100)

const asyncForEach = async (array, callback) => {
    for ( let index = 0; index < array.length; index++ ) {
        await callback(array[index], index, array)
    }
}

const state = {
    bookingsNEW: null,
    bookingsSearchNEW: null,
    bookingsIdDataNEW: null,
    bookingsPostSuccess: [],
    bookingsPostFailed: [],
    bookingsProgress: 0,

    bookings: null,
    bookingsByCampaignIdData: null,
    bookingsByLocationIdData: null,
    bookingById: null,
    bookingsBySearchData: null,
    selectedBookings: [],
    bookingsAdjustedOrCancelled: false,
    bookingsSearch: null,
    normalisedDataTest: null,
    normalisedDataTestList: null,

    ordering: [
        { text: 'Campaign (asc)', value: 'campaign' },
        { text: 'Campaign (desc)', value: '-campaign' },
        { text: `${names.locations.label.singular} (asc)`, value: 'location' },
        { text: `${names.locations.label.singular} (desc)`, value: '-location' },
        { text: 'Start Date (asc)', value: 'start_date' },
        { text: 'Start Date (desc)', value: '-start_date' },
        { text: 'End Date (asc)', value: 'end_date' },
        { text: 'End Date (desc)', value: '-end_date' },
        { text: 'Creative Coverage (asc)', value: 'media_coverage' },
        { text: 'Creative Coverage (desc)', value: '-media_coverage' },
        { text: 'Updated (First)', value: '-updated' },
        { text: 'Updated (Last)', value: 'updated' },
        { text: 'Created (First)', value: 'created' },
        { text: 'Created (Last)', value: '-created' }
    ],
    headers: [
        { text: 'Campaign', align: 'left', sortable: true, value: 'campaign' },
        { text: names.locations.label.singular, align: 'left', sortable: true, value: 'location', class: 'hidden-xs-only'  },
        // { text: 'Start', align: 'left', sortable: true, value: 'start_date', class: 'hidden-md-and-down' },
        // { text: 'End', align: 'left', sortable: true, value: 'end_date', class: 'hidden-md-and-down' },
        { text: 'Start / End', align: 'left', sortable: false, class: '' },
        // { text: 'Spot Count', align: 'left', sortable: false, class: 'hidden-md-and-down' },
        // { text: 'Spot Duration', align: 'left', sortable: false, class: 'hidden-md-and-down' },
        { text: 'Spot', align: 'left', sortable: false, class: 'hidden-sm-and-down' },
        // { text: 'Projected SOV', align: 'left', sortable: false, class: 'hidden-md-and-down' },
        // { text: 'SOV', align: 'left', sortable: false, class: 'hidden-sm-and-down hidden-lg-and-up' },
        { text: 'Creative Coverage', align: 'left', sortable: true, value: 'media_coverage', class: 'hidden-sm-and-down' },
        { text: 'Delivery (plays)', align: 'left', sortable: false, class: 'hidden-sm-and-down' },
        { text: '', align: 'left', sortable: false, class: 'hidden-sm-and-up' }
    ],
    pagination: {
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        page: 1,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    },
    query: {
        limit: 10,
        offset: 0,
        q: undefined,
        ordering: undefined,
        paging: undefined,
        campaign: undefined,
        location: undefined,
        start_date__lt: undefined,
        start_date__lte: undefined,
        start_date__gt: undefined,
        start_date__gte: undefined,
        end_date__lt: undefined,
        end_date__lte: undefined,
        end_date__gt: undefined,
        end_date__gte: undefined,
        media_coverage__lt: undefined,
        media_coverage__lte: undefined,
        media_coverage: undefined,
        media_coverage__gt: undefined,
        media_coverage__gte: undefined,
        filters: undefined        
    },
    queryFilterKeys: [
        'campaign',
        'location',
        'filters',
        'start_date__lt',
        'start_date__lte',
        'start_date__gt',
        'start_date__gte',
        'end_date__lt',
        'end_date__lte',
        'end_date__gt',
        'end_date__gte',
        'media_coverage__lt',
        'media_coverage__lte',
        'media_coverage',
        'media_coverage__gt',
        'media_coverage__gte'
    ]
}

const getters = {
    bookingsResultsNEW: ({ bookingsNEW }) => bookingsNEW ? 
        bookingsNEW.result.results.map(bookingId => bookingsNEW.entities.bookings[bookingId]) : [],
    bookingsCountNEW: ({ bookingsNEW }) => bookingsNEW ? 
        bookingsNEW.result.count : 0,
    bookingsSearchResultsNEW: ({ bookingsSearchNEW }) => bookingsSearchNEW ? 
        bookingsSearchNEW.result.results.map(id => bookingsSearchNEW.entities.bookings[id]) : [],
    bookingsSearchCountNEW: ({ bookingsSearchNEW }) => bookingsSearchNEW ? 
        bookingsSearchNEW.result.count : 0,


    bookings ({ bookings }) {
        return bookings ? bookings.data.results : []
    },
    // bookingsByCampaignId (state) {
    //     return state.bookingsByCampaignIdData ? state.bookingsByCampaignIdData.results : []
    // },
    bookingsByCampaignId ({ bookingsByCampaignIdData }) { // ({ property of state object }) instead of (state)
        return bookingsByCampaignIdData ? bookingsByCampaignIdData.results : []
    },
    bookingsByCampaignIdCount ({ bookingsByCampaignIdData }) {
       return bookingsByCampaignIdData ? bookingsByCampaignIdData.count : 0
    },
    bookingsByLocationId ({ bookingsByLocationIdData }) {
        return bookingsByLocationIdData ? bookingsByLocationIdData.results : []
    },
    bookingsByLocationIdCount ({ bookingsByLocationIdData }) {
        return bookingsByLocationIdData ? bookingsByLocationIdData.count : 0
    },
    bookingById ({ bookingById }) {
        return bookingById ? bookingById.data : {}
    },
    // bookingsBySearch ({ bookingsBySearchData }) {
    //     return bookingsBySearchData ? bookingsBySearchData.results : []
    // },
    // bookingsBySearchCount ({ bookingsBySearchData }) {
    //     return bookingsBySearchData ? bookingsBySearchData.count : 0
    // },

    bookingsSearch ({ bookingsSearch }) {
        return bookingsSearch ? bookingsSearch.data.results : []
    },
    bookingsSearchCount ({ bookingsSearch }) {
        return bookingsSearch ? bookingsSearch.data.count : 0
    },

    normalisedDataResults ({ normalisedDataTestList, normalisedDataTest }) {
        if ( normalisedDataTest ) return normalisedDataTestList.map( id => normalisedDataTest.bookings[id] )
    },
    normalisedDataCount ({ normalisedDataTest }) {
        return normalisedDataTest.count
    },
    bookingsProgress ({ bookingsProgress }) {
        return bookingsProgress
    },
    normalizrTest ({ bookingsSearch }) {
        const bookingsResults = bookingsSearch ? bookingsSearch.data.results : []

        const mediaItem = new schema.Entity('mediaItems')
        const mediaInstruction = new schema.Entity('mediaInstructions', {
            media_items: [ mediaItem ]
        })
        const booking = new schema.Entity('bookings', {
            media_instructions: [ mediaInstruction ]
        })        
        const bookings = [ booking ]
        // const booking = new schema.Entity('bookings')
        // const mySchema = { bookings: [booking] }
        const normalizedData = normalize(bookingsResults, bookings);
        // return originalData
        // const location = new schema.Entity('locations')
        // const campaign = new schema.Entity('campaigns')
        // const booking = new schema.Entity('bookings')
        // const bookingSchema = { bookings: [booking] }
        // const normalizedData = normalize(originalData, bookingSchema)
        // return normalizedData
        return normalizedData//.result.map( bookingId => normalizedData.entities.bookings[bookingId] )
    },
    userSet: (state) => state.userList.map( userId => state.users[userId] ),

    queryFiltersActive ({ query, queryFilterKeys }) {
        return queryFilterKeys.some(x => query[x])
    },
}

const mutations = {
    setBookingsNEW (state, payload) {
        state.bookingsNEW = payload ? normalize(payload, { results: [ bookingSchema ] }) : null
    },
    setBookingsSearchNEW (state, payload) {
        state.bookingsSearchNEW = payload ? normalize(payload, { results: [ bookingSchema ] }) : null
    },
    setBookingsPostSuccess: (state, payload) => state.bookingsPostSuccess = payload,
    setBookingsPostFailed: (state, payload) => state.bookingsPostFailed = payload,


    setBookings (state, payload) {
        state.bookings = payload
    },
    setBookingsByCampaignIdData (state, payload) {
        state.bookingsByCampaignIdData = payload
    },
    setBookingsByLocationIdData (state, payload) {
        state.bookingsByLocationIdData = payload
    },
    setBookingById (state, payload) {
        state.bookingById = payload
    },
    // setBookingsBySearchData (state, payload) {
    //     state.bookingsBySearchData = payload
    // },
    setSelectedBookings (state, payload) {
        state.selectedBookings = payload
    },
    setSelectedBookingStatus (state, payload) {
        const matchingSelectedBooking = state.selectedBookings.find(booking => booking.id === payload.booking.id)
        payload.this.$set(matchingSelectedBooking, 'status', payload.status)
    },
    setSelectedBookingProperties (state, 
        { booking, 
            status = booking.status, 
            start_date = booking.start_date, 
            end_date = booking.end_date, 
            item_duration = booking.item_duration,
            spot_count = booking.spot_count
        }) {
        booking.status = status
        booking.start_date = start_date
        booking.end_date = end_date
        booking.item_duration = item_duration
        booking.spot_count = spot_count
    },
    setBookingsAdjustedOrCancelled (state, payload) {
        state.bookingsAdjustedOrCancelled = payload
    },
    setBookingsSearch (state, payload) {
        // const { config, ...rest } = payload
        // state.bookingsSearch = rest //fixes object mutation thing.
        state.bookingsSearch = payload ? { data: payload.data } : null //fixes object mutation thing.
        // implement normalised data structures.
    },
    setNormalisedDataTest (state, payload) {
        // only does one level
        const { data } = payload
        state.normalisedDataTest = {
            count: data.count,
            bookings: data.results.length ? Object.assign({}, ...data.results.map(x => ({[x.id]: x }))) : null
        }
        state.normalisedDataTestList = data.results.map(x => x.id)
    },
    setBookingsProgress (state, payload) {
        state.bookingsProgress = payload
    },

    
    setPagination (state, payload) {
        state.pagination = payload        
    },
    setPaginationDescending (state, payload) {
        state.pagination.sortDesc = payload        
    },
    setPaginationSortBy (state, payload) {
        state.pagination.sortBy = payload        
    },
    setQueryLimit (state, payload) {
        state.query.limit = payload
    },
    setQueryOffset (state, payload) {
        state.query.offset = payload
    },
    setQuerySearch (state, payload) {
        state.query.q = payload
    },
    setQueryOrdering (state, payload) {
        state.query.ordering = payload
    },
    setQueryPaging (state, payload) {
        state.query.paging = payload
    },
    setQueryProperty (state, { property, value }) {
        state.query[property] = value
    },
}

const actions = {
    async getBookingsNEW({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            commit('setBookingsNEW', await api.get('/bookings', payload).then(response => response.data))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getBookingsLocalNEW({ commit }, payload) {
        try {
            return await api.get('/bookings', payload).then(response => response.data)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
            throw error
        }
    },
    async getBookingsSearchNEW({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            commit('setBookingsSearchNEW', await api.get('/bookings/search', payload).then(response => response.data))            
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getBookingsSearchLocalNEW({ commit }, payload) {
        try {
            return await api.get('/bookings/search', payload).then(response => response.data.results)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
            throw error
        }
    },


    async postBookingsBatchCreate ({ commit }, payload) {
        try {
            return await api.post('/bookings', payload)
        } catch (error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
            // return Promise.reject(error)
            throw error
        }
    },
    async postBookingsBatchUpdate ({ commit }, payload) {
        try {
            return await api.post('/bookings/batch_update', payload)
        } catch (error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
            // return Promise.reject(error)
            throw error
        }
    },
    async postBookingsBatchDelete ({ commit }, payload) {
        try {
            return await api.post('/bookings/batch_delete', payload)
        } catch (error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
            // return Promise.reject(error)
            throw error
        }
    },
    async getTask ({ commit }, id) {
        try {
            // commit('setLoading', true, { root: true })
            return await api.get(`/tasks/${id}`)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
            throw error
        } finally {
            // commit('setLoading', false, { root: true })
        }
    },

    setPagination({ commit }, payload) {
        commit('setPagination', payload)
        if ( payload.itemsPerPage> 0 ) {
            commit('setQueryLimit', payload.itemsPerPage)
            commit('setQueryOffset', (payload.page - 1) * payload.itemsPerPage)
            commit('setQueryPaging', undefined)
        } else {
            commit('setQueryLimit', undefined)
            commit('setQueryOffset', undefined)
            commit('setQueryPaging', 'none')
        }
        if ( payload.sortBy[0] ) {
            if ( payload.sortDesc[0] ) {                
                commit('setQueryOrdering', `-${payload.sortBy[0]}`)  
            } else {
                commit('setQueryOrdering', payload.sortBy[0])
            }
        } else {
            commit('setQueryOrdering', undefined)
        }
    },
    resetPagination({ state, commit, dispatch }) {
        dispatch('setPagination', {
            sortDesc: [],
            page: 1,
            itemsPerPage: state.pagination.itemsPerPage, 
            sortBy: [],
            // totalItems: 0
        })
        commit('setQuerySearch', undefined)
        state.queryFilterKeys.forEach( x => {
            commit('setQueryProperty', { property: x, value: undefined })
        })
    },
    setOrdering({ commit }, payload) {
        commit('setQueryOrdering', payload)
        if ( payload ) {
            if ( payload.startsWith('-') ) {
                commit('setPaginationDescending', [true])
                commit('setPaginationSortBy', [payload.slice(1)])
            } else {
                commit('setPaginationDescending', [])
                commit('setPaginationSortBy', [payload])
            }
        } else {
            commit('setPaginationDescending', [])
            commit('setPaginationSortBy', [])
        }
    },


    // async postBookingsNEW ({ commit }, payload) {
    //     commit('setBookingsPostSuccess', [])
    //     commit('setBookingsPostFailed', [])
    //     let successList = []
    //     let failedList = []
    //     let remaining = payload.length
    //     // commit('setLoading', true, { root: true })
    //     await asyncForEach(payload, async booking => {
    //         try {
    //             // if ( booking.location_id === 14 ) booking.location_id = 'potato' // ###############################################
    //             await api.post('/bookings', booking)
    //             .then(() => {
    //                 successList.push(booking)
    //             })
    //             .catch(() => {
    //                 // booking.location_id = 14 // ###################################################################################
    //                 failedList.push(booking)
    //             })
    //         } catch(error) {
    //             commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
    //         } finally {
    //             remaining -= 1
    //             commit('setBookingsProgress', Progress(remaining, payload.length))
    //             commit('setBookingsPostSuccess', [ ...successList])
    //             commit('setBookingsPostFailed', [ ...failedList])
    //         }
    //     })
    //     // commit('setLoading', false, { root: true })
    //     if ( failedList.length ) {
    //         commit('setSnackbar', { type: 'error', msg: 'There were errors creating bookings' }, { root: true })
    //     } else {
    //         commit('setSnackbar', { type: 'success', msg: 'Bookings Created.' }, { root: true })
    //     }
    // },
    // async adjustBookingsNEW({ commit, state }, payload) {
    //     let remaining = state.selectedBookings.length
    //     // commit('setLoading', true, { root: true })
    //     await asyncForEach(state.selectedBookings, async booking => {
    //         try {
    //             const res = await api.patch(`/bookings/${booking.id}`, payload)
    //             commit('setSelectedBookingProperties', { 
    //                 booking, 
    //                 status: 'UPDATED',
    //                 start_date: res.data.start_date,
    //                 end_date: res.data.end_date,
    //                 item_duration: res.data.item_duration,
    //                 spot_count: res.data.spot_count
    //             })
    //         } catch(error) {
                
    //             console.log(error)
    //             if ( error.status === 409 ) {
    //                 commit('setSelectedBookingProperties', { booking, status: 'CONFLICT' })
    //             } else {
    //                 commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
    //             }
    //         } finally {
    //             remaining -= 1
    //             commit('setBookingsProgress', Progress(remaining, state.selectedBookings.length))
    //         }
    //     })
    //     // commit('setLoading', false, { root: true })
    // },

    // async cancelBookingsNEW({ commit, state }) {
    //     // commit('setLoading', true, { root: true })                
    //     let remaining = state.selectedBookings.length
        
    //     const now = new Date()
    //     let localTZOffset = now.getTimezoneOffset() * 60000
    //     const nowMinusDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
    //     let [nowMinusDayDate, nowMinusDayTime] = (new Date(nowMinusDay - localTZOffset).toISOString()).split('T')        
    //     await asyncForEach(state.selectedBookings, async booking => {
    //         const bookingStart = new Date(booking.start_date + 'T00:00:00')
    //         const bookingStartPlusDay = new Date(bookingStart.getFullYear(), bookingStart.getMonth(), bookingStart.getDate() + 1)
    //         const bookingEnd = new Date(booking.end_date + 'T23:59:59.999999')
    //         if ( now < bookingStartPlusDay ) {
    //             try {
    //                 await api.delete(`/bookings/${booking.id}`)
    //                 commit('setSelectedBookingProperties', { booking, status: 'CANCELLED' })
    //             } catch(error) {
    //                 commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
    //             }
    //         } else if ( bookingStartPlusDay <= now && now <= bookingEnd ) {
    //             try {
    //                 const res = await api.patch(`/bookings/${booking.id}`, { end_date: nowMinusDayDate })
    //                 commit('setSelectedBookingProperties', { 
    //                     booking, 
    //                     status: 'END-DATED',
    //                     end_date: res.data.end_date
    //                 })
    //             } catch(error) {
    //                 commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
    //             }
    //         } else if ( now > bookingEnd ) {
    //             commit('setSelectedBookingProperties', { booking, status: 'FINISHED' })
    //         }
    //         remaining -= 1
    //         commit('setBookingsProgress', Progress(remaining, state.selectedBookings.length))
    //     })
    //     commit('setLoading', false, { root: true })
    // },

    async getBookings({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            commit('setBookings', await api.get('/bookings', payload))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getBookingsByCampaignId({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            const res = await api.get(`/bookings?campaign=${payload.id}`, { params: payload.query })
            commit('setBookingsByCampaignIdData', res.data)            
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getBookingsByLocationId({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            const res = await api.get(`/bookings?location=${payload.id}`, { params: payload.query })
            commit('setBookingsByLocationIdData', res.data)            
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getBookingsBySearch({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            // const res = await api.get(`/bookings/search?filters=${encodeURIComponent(JSON.stringify(payload.body))}`, { params: payload.query })
            commit('setBookingsSearch', await api.get('/bookings/search', payload))
            // const res = await api.get('/bookings/search', payload)
            // commit('setBookingsSearch', { data: res.data }) //nested object mutation thing. caused by config object. move destructure to mutation function.
            
            // commit('setNormalisedDataTest', await api.get('/bookings/search', payload))

            //the await is thenable.
            // commit('someThing', await api.get('/bookings', payload).then((response) => response.data.count))
            
            
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getBookingById({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            commit('setBookingById', await api.get(`/bookings/${id}`))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async postBookings({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            await api.post('/bookings', payload.bookings)
            commit('setSnackbar', { type: 'success', msg: 'Bookings Created.' }, { root: true })
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },

    





    async putBooking({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            await api.put(`/bookings/${payload.id}`, payload.formData)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async adjustBooking({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            await api.patch(`/bookings/${payload.id}`, payload.formData)
            commit('setSnackbar', { type: 'success', msg: 'Booking Adjusted' }, { root: true })
        } catch(error) {
            if ( error.status === 409 ) {
                commit('setSnackbar', { type: 'error', msg: 'Booking cannot be adjusted due to conflict.' }, { root: true })
                throw error
            } else {
                commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
            }
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    adjustBookings({ commit, state }, payload) {
        let remaining = state.selectedBookings.length
        state.selectedBookings.forEach(async booking => {
            try {
                commit('setLoading', true, { root: true })
                const res = await api.patch(`/bookings/${booking.id}`, payload.formData)
                commit('setSelectedBookingProperties', { 
                    booking, 
                    status: 'UPDATED',
                    start_date: res.data.start_date,
                    end_date: res.data.end_date,
                    item_duration: res.data.item_duration,
                    spot_count: res.data.spot_count
                })
            } catch(error) {
                if ( error.status === 409 ) {
                    commit('setSelectedBookingProperties', { booking, status: 'CONFLICT' })
                } else {
                    commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
                }
            } finally {
                commit('setLoading', false, { root: true })
                remaining -= 1
                commit('setBookingsProgress', Progress(remaining, state.selectedBookings.length))
            }
        })
    },
    async cancelBooking({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            const res = await api.delete(`/bookings/${id}`)
            if ( res.status === 200 ) {
                if ( res.data.detail ) {
                    commit('setSnackbar', { type: 'success', msg: res.data.detail }, { root: true })
                }
            }
            if ( res.status === 204 ) {
                // if ( res.data.detail ) {
                //     commit('setSnackbar', { type: 'success', msg: res.data.detail }, { root: true })
                // } else {
                //     commit('setSnackbar', { type: 'success', msg: 'Booking Cancelled' }, { root: true })
                // }
                commit('setSnackbar', { type: 'success', msg: 'Booking Cancelled' }, { root: true })
                router.replace({ name: 'CampaignBookingsList' })
            }
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    cancelBookings({ commit, state }) {
        let remaining = state.selectedBookings.length        
        state.selectedBookings.forEach(async booking => {
            try {
                commit('setLoading', true, { root: true })                
                const res = await api.delete(`/bookings/${booking.id}`)
                if ( res.status === 200 ) {
                    commit('setSelectedBookingProperties', { 
                        booking, 
                        status: 'END-DATED',
                        end_date: res.data.end_date
                    })
                } else if ( res.status === 204 ) {
                    commit('setSelectedBookingProperties', { booking, status: 'CANCELLED' })
                }
            } catch(error) {
                if ( error.status === 403 ) {
                    commit('setSelectedBookingProperties', { booking, status: 'FORBIDDEN' })
                } else {
                    commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
                }
            } finally {
                commit('setLoading', false, { root: true })
                remaining -= 1
                commit('setBookingsProgress', Progress(remaining, state.selectedBookings.length))
            }
        })
    },
    async deleteBooking({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            await api.delete(`/bookings/${id}`)
            // const res = await api.delete(`/bookings/${id}`)
            // router.replace({ name: 'CampaignBookingsList' })
            // console.log(res)
            // return res
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
            // console.log(error)
            // throw error
        } finally {
            commit('setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
