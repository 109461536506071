import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/plugins/store'
import names from '@/configs/app_names'
import groups from '@/configs/user_groups'
import ViewHeaderSidebar from '@/views/ViewHeaderSidebar'

Vue.use(VueRouter)

async function requireAuth (to, from, next) {
    // const userAuthenticated = await store.dispatch('cognito/getCurrentAuthenticatedUser')
    // if ( userAuthenticated ) {
    //     next()
    // } else {
    //     next({
    //         path: '/auth/signin',
    //         query: from.fullPath === '/' ? undefined : { redirect: to.fullPath } // The redirect no longer works with cognito ui flow.
    //     })
    // }
}

const router = new VueRouter({
    // mode: 'history', //preferred for final prod. but requires server to be configured to always serve index.html
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        // HOME
        {
            name: 'Home',
            path: '/',
            redirect: { name: 'Campaigns' }
        },
        // PDF Test
        {
            name: 'PDF',
            path: '/pdf',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewPdf')
            },
        },
        // AUTH
        { 
            path: '/auth/signin',
            name: 'SignIn',
            component: () => import('@/views/ViewAuthSignIn'),
            // async beforeEnter (to, from, next) {
            //     if ( await store.dispatch('cognito/getCurrentAuthenticatedUser') ) {
            //         next('/')
            //     } else {
            //         next()
            //     }
            // }
        },
        {
            path: '/auth/forgot-password',
            component: () => import('@/views/ViewAuthForgotPassword')
        },
        // NO ACCESS
        {
            name: 'NoAccess',
            path: '/auth/no-access-role',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewAuthNoAccess')
            }
        },
        // CAMPAIGNS
        {
            path: '/campaigns',
            // name: 'Campaigns',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewCampaigns')
            },
            // // // beforeEnter: requireAuth,
            // redirect: { name: 'CampaignsList' },
            children: [
                {
                    path: '',
                    name: 'CampaignsGroup',
                    redirect: { name: 'Campaigns' },
                    
                },
                // {
                //     path: 'list',
                //     name: 'CampaignsList',
                //     component: () => import('@/components/aList'),
                //     // beforeEnter: requireAuth
                // },
            //     {
            //         path: 'categories',
            //         name: 'CampaignCategories',
            //         component: () => import('@/components/ViewCampaignCategories'),
            //         // beforeEnter: requireAuth
            //     },
            ]
        },
        {
            path: '/campaigns/list',
            name: 'Campaigns',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewCampaigns')
            },
            // // beforeEnter: requireAuth
        },
        {
            path: '/campaigns/categories',
            name: 'CampaignCategories',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewCampaignCategories')
            },
            // beforeEnter: requireAuth
        },
        {
            path: '/campaigns/bookings',
            name: 'Bookings',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewBookings')
            },
            // beforeEnter: requireAuth
        },
        {
            path: '/campaigns/bookings/:bid',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewBookingById'),
            },
            // // beforeEnter: requireAuth,
            children: [
                {
                    path: '',
                    name: 'BookingById',
                    redirect: { name: 'BookingDetails' },
                },
                {
                    path: 'details',
                    name: 'BookingDetails',
                    // component: () => import('@/components/BookingDetails'),
                    component: () => import('@/components/CampaignBookingDetails'),
                    // beforeEnter: requireAuth
                }
            ]
        },
        {
            path: '/campaigns/:id',
            // name: 'CampaignById',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewCampaignById'),
            },
            // props: { headerSidebar: false, default: true },
            // // beforeEnter: requireAuth,
            children: [
                {
                    path: '',
                    name: 'CampaignById',
                    redirect: { name: 'CampaignBookingsList' },
                },
                // {
                //     path: 'overview',
                //     name: 'CampaignOverview',
                //     component: () => import('@/components/CampaignOverview'),
                //     // beforeEnter: requireAuth
                // },
                {
                    path: 'bookings',
                    name: 'CampaignBookingsList',
                    component: () => import('@/components/CampaignBookingsList'),
                    // beforeEnter: requireAuth
                },
                {
                    path: 'bookings/add',
                    name: 'CampaignBookingsAdd',
                    component: () => import('@/components/CampaignBookingsAdd'),
                    // beforeEnter: requireAuth
                },
                {
                    path: 'bookings/:bid',
                    name: 'CampaignBookingDetails',
                    component: () => import('@/components/CampaignBookingDetails'),
                    // beforeEnter: requireAuth
                },
                {
                    path: 'media_schedules',
                    name: 'CampaignMediaSchedulesList',
                    component: () => import('@/components/CampaignMediaSchedulesList'),
                    // beforeEnter: requireAuth
                },
                {
                    path: 'media_schedules/create',
                    name: 'CampaignMediaSchedulesCreate',
                    component: () => import('@/components/CampaignMediaSchedulesCrud'),
                    props: true,
                    // beforeEnter: requireAuth
                },
                {
                    path: 'media_schedules/:msid',
                    name: 'CampaignMediaSchedulesReadUpdateDelete',
                    component: () => import('@/components/CampaignMediaSchedulesCrud'),
                    // beforeEnter: requireAuth
                },
                {
                    path: 'reports',
                    name: 'CampaignReportsPlaceholder',
                    component: () => import('@/components/CampaignReportsPlaceholder'),
                    // beforeEnter: requireAuth
                }
            ]
        },
        // MEDIA
        {
            // MEDIA LIST
            name: names.media.route,
            path: names.media.path,
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewMedia'),
            },
            // beforeEnter: requireAuth
        },
        {
            // MEDIA INSTANCE
            path: names.media.path + '/:id',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewMediaById'),
            },
            // props: { headerSidebar: false, default: true },
            // // beforeEnter: requireAuth,
            children: [
                {
                    // DEFAULT
                    path: '',
                    name: names.media.route + 'Instance',
                    redirect: { name: names.media.route + 'Instance' + 'Details' },
                },
                {
                    // DETAILS
                    name: names.media.route + 'Instance' + 'Details',
                    path: 'details',
                    component: () => import('@/components/MediaDetail'),
                    // beforeEnter: requireAuth
                }
            ]
        },
        // REACTIVE MEDIA
        {
            path: '/dynamic_media',
            name: 'DynamicMediaList',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewReactiveMedia'),
            },
            // beforeEnter: requireAuth
        },
        {
            path: '/reactive_media/:id',
            name: 'ReactiveMediaById',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewReactiveMediaById'),
            },
            // beforeEnter: requireAuth
        },
        {
            path: '/reactive_media/create',
            name: 'ReactiveMediaCreate',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewReactiveMediaById'),
            },
            props: true,
            // beforeEnter: requireAuth
        },

        // LOCATIONS
        // {
        //     // LOCATIONS GROUP
        //     name: names.locations.routeGroup,
        //     path: names.locations.pathGroup,
        //     redirect: { name: names.locations.route },
        //     // beforeEnter: requireAuth
        // },
        // {
        //     // LOCATIONS LIST
        //     name: names.locations.route,
        //     path: names.locations.pathGroup + names.locations.path,
        //     components: {
        //         headerSidebar: ViewHeaderSidebar,
        //         default: () => import('@/views/ViewLocations')
        //     },
        //     // beforeEnter: requireAuth
        // },
        {
            // LOCATIONS LIST
            name: 'Locations',
            path: '/locations',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewLocations')
            },
            // beforeEnter: requireAuth
        },  
        // {
        //     // LOCATIONS INSTANCE
        //     path: names.locations.pathGroup + names.locations.path + '/:id',
        //     components: {
        //         headerSidebar: ViewHeaderSidebar,
        //         default: () => import('@/views/ViewLocationById'),
        //     },
        //     // // beforeEnter: requireAuth,
        //     children: [
        //         {
        //             // DEFAULT
        //             name: names.locations.route + 'Instance',
        //             path: '',
        //             redirect: { name: names.locations.route + 'Instance' + 'Details' },
        //         },
        //         {
        //             // DETAILS
        //             name: names.locations.route + 'Instance' + 'Details',
        //             path: 'details',
        //             component: () => import('@/components/LocationDetails'),
        //             // beforeEnter: requireAuth
        //         },
        //         {
        //             // LOCATION LOOPS
        //             name: names.locations.route + 'Instance' + 'Loops',
        //             path: 'media_unit_loops',
        //             // name: 'LocationLoopsList',
        //             component: () => import('@/components/LocationLoopsList'),
        //             // beforeEnter: requireAuth
        //         },
        //         {
        //             // LOCATION LOOPS INSTANCE
        //             name: names.locations.route + 'Instance' + 'Loops' + 'Instance',
        //             path: 'media_unit_loops/:pid',
        //             // name: 'LocationLoopById',
        //             component: () => import('@/components/LocationLoopById'),
        //             // beforeEnter: requireAuth
        //         }
        //     ]
        // },
        {
            // LOCATIONS INSTANCE
            path: '/locations/:id',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewLocationById'),
            },
            // // beforeEnter: requireAuth,
            children: [
                {
                    // DEFAULT
                    name: 'LocationsInstance',
                    path: '',
                    redirect: { name: 'LocationsInstanceDetails' },
                },
                {
                    // DETAILS
                    name: names.locations.route + 'Instance' + 'Details',
                    path: 'details',
                    component: () => import('@/components/LocationDetails'),
                    // beforeEnter: requireAuth
                },
                {
                    // LOCATION LOOPS
                    name: 'LocationsInstanceLoops',
                    path: 'loops',
                    // name: 'LocationLoopsList',
                    component: () => import('@/components/LocationLoopsList'),
                    // beforeEnter: requireAuth
                },
                {
                    // LOCATION LOOPS INSTANCE
                    name: names.locations.route + 'Instance' + 'Loops' + 'Instance',
                    path: 'loops/:pid',
                    // name: 'LocationLoopById',
                    component: () => import('@/components/LocationLoopById'),
                    // beforeEnter: requireAuth
                }
            ]
        },
        // ATTRIBUTES
        {
            path: '/location_attributes',
            // path: '/locations/attributes',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewList')
            },
            props: { 
                // default: { title: 'Location Attributes' }
                default: { title: names.locations.label.singular + ' Attributes'}
            },
            // // beforeEnter: requireAuth,
            children: [
                {
                    path: '',
                    name: 'Attributes',
                    component: () => import('@/components/LocationAttributesList'),
                }
            ]
        },
        {
            path: '/location_attributes/:laid',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewLocationAttributeById'),
            },
            // // beforeEnter: requireAuth,
            children: [
                {
                    name: 'AttributeById',
                    path: '',
                    redirect: { name: 'AttributeValuesList' },
                },
                // {
                //     path: 'details',
                //     name: 'LocationAttributeDetails',
                //     component: () => import('@/components/LocationAttributeDetails'),
                //     // beforeEnter: requireAuth
                // },
                {
                    name: 'AttributeValuesList',
                    path: 'values',
                    component: () => import('@/components/LocationAttributeValuesList'),
                    // beforeEnter: requireAuth
                }
            ]
        },
        // CONTENT SPECIFICATIONS
        {
            path: '/content_specifications',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewList')
            },
            props: { 
                default: { title: 'Content Specifications' }
            },
            // // beforeEnter: requireAuth,
            children: [
                {
                    name: 'ContentSpecifications',
                    path: '',
                    component: () => import('@/components/ContentSpecificationsList'),
                }
            ]
        },
        {
            path: '/content_specifications/:csid',
            name: 'ContentSpecificationById',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewContentSpecificationById'),
            },
            // beforeEnter: requireAuth
        },
        // OPERATING HOURS POLICIES
        {
            path: '/operating_hours_policies',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewList')
            },
            props: { 
                default: { title: 'Operating Hours Policies' }
            },
            // // beforeEnter: requireAuth,
            children: [
                {
                    name: 'OperatingHoursPolicies',
                    path: '',
                    component: () => import('@/components/OperatingHoursPoliciesList')
                }
            ]
        },
        {
            path: '/operating_hours_policies/:oopid',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewOperatingHoursPolicyById'),
            },
            // // beforeEnter: requireAuth,
            children: [
                {
                    name: 'OperatingHoursPolicyById',
                    path: '',
                    redirect: { name: 'OperatingHoursPolicyDetails' },
                },
                {
                    name: 'OperatingHoursPolicyDetails',
                    path: 'details',
                    component: () => import('@/components/OperatingHoursPolicyDetails'),
                    // beforeEnter: requireAuth
                }
            ]
        },        
        // LOOPS
        {
            path: '/loops',
            name: 'Loops',
            // components: {
            //     headerSidebar: ViewHeaderSidebar,
            //     default: () => import('@/views/ViewLoops')
            // },
            redirect: { name: 'LoopsList' },
            // beforeEnter: requireAuth
        },
        {
            path: '/loops/list',
            name: 'LoopsList',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewLoops')
            },
            // beforeEnter: requireAuth
        },        
        {
            path: '/loop_policies',
            // name: 'LoopPolicies',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewList')
            },
            props: { 
                default: { title: 'Loop Policies' }
            },
            // // beforeEnter: requireAuth,
            children: [
                {
                        path: '',
                        name: 'LoopPolicies',
                        component: () => import('@/components/LoopPoliciesList'),
                    },
                    {
                            path: 'add',
                            name: 'LoopPoliciesAdd',
                    component: () => import('@/components/LoopPoliciesAdd'),
                    // beforeEnter: requireAuth
                }
            ]
        },
        {
            path: '/loop_policies/:ppid',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewLoopPolicyById'),
            },
            // // beforeEnter: requireAuth,
            children: [
                {
                    path: '',
                    name: 'LoopPolicyById',
                    redirect: { name: 'LoopPolicyDetails' },
                },
                {
                    path: 'details',
                    name: 'LoopPolicyDetails',
                    component: () => import('@/components/LoopPolicyDetails'),
                    // beforeEnter: requireAuth
                },
                {
                    path: 'edit',
                    name: 'LoopPolicyEdit',
                    component: () => import('@/components/LoopPolicyEdit'),
                    // beforeEnter: requireAuth
                }
            ]
        },
        {
            path: '/loops/:pid',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewLoopById'),
            },
            // // beforeEnter: requireAuth,
            children: [
                {
                    path: '',
                    name: 'LoopById',
                    redirect: { name: 'LoopDetails' },
                },
                {
                    path: 'details',
                    name: 'LoopDetails',
                    component: () => import('@/components/LoopDetails'),
                    // beforeEnter: requireAuth
                }
            ]
        },
        // SITES
        {
            path: '/sites',
            name: 'Sites',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewSites')
            },
            // beforeEnter: requireAuth
        },
        {
            path: '/sites/:sid',
            components: {
                headerSidebar: ViewHeaderSidebar,
                default: () => import('@/views/ViewSiteById'),
            },
            // // beforeEnter: requireAuth,
            children: [
                {
                    path: '',
                    name: 'SiteById',
                    redirect: { name: 'SiteDetails' },
                },
                {
                    path: 'details',
                    name: 'SiteDetails',
                    component: () => import('@/components/SiteDetails'),
                    // beforeEnter: requireAuth
                },
                {
                    path: 'loops',
                    name: 'SiteLocationsList',
                    component: () => import('@/components/SiteLocationsList'),
                    // beforeEnter: requireAuth
                }
            ]
        },
        // FALLBACK
        {
            path: '*',
            redirect: '/'
        }
        // {
        //     path: '*',
        //     beforeEnter: (to, from, next) => {
        //       next('/');
        //     }
        // }
    ]
})

router.beforeEach(async (to, from, next) => {
    try {
        await store.dispatch('cognito/getCurrentAuthenticatedUser')
        if ( store.state.cognito.user ) {
            if (store.getters['cognito/userGroupExists']) {
                if ( to.name === 'SignIn' || to.name === 'NoAccess' ) next({ name: 'Home' })
                else if ( store.getters['cognito/userGroupAllowedRoutes'].includes(to.name) ) next()
            } else {
                if ( to.name === 'NoAccess' ) next()
                else next({ name: 'NoAccess'})
            }
        } else {
            if ( to.name === 'SignIn' ) next()
            else next({ name: 'SignIn'})
        }
    } catch (error) {
        console.log('Router error:', error)
    }
})

export default router
