import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/plugins/api'
// import router from '@/plugins/router'
import app from '@/plugins/store/modules/app'
import cognito from '@/plugins/store/modules/cognito'
import media from '@/plugins/store/modules/media'
import reactiveMedia from '@/plugins/store/modules/reactiveMedia'
import campaigns from '@/plugins/store/modules/campaigns'
import bookings from '@/plugins/store/modules/bookings'
import mediaSchedules from '@/plugins/store/modules/mediaSchedules'
import reports from '@/plugins/store/modules/reports'
import locations from '@/plugins/store/modules/locations'
import loops from '@/plugins/store/modules/loops'
import sites from '@/plugins/store/modules/sites'
import loopPolicies from '@/plugins/store/modules/loopPolicies'
import operatingHoursPolicies from '@/plugins/store/modules/operatingHoursPolicies'

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: true,
    modules: {
        app,
        cognito,
        media,
        reactiveMedia,
        campaigns,
        bookings,
        mediaSchedules,
        reports,
        locations,
        loops,
        sites,
        loopPolicies,
        operatingHoursPolicies
    },
    state: {
        productVersion: require('../../../package.json').version,
        loading: false,
        snackbar: { type: null, msg: null },
        // rowsPerPageItems: [5, 10, 25, 50, 100, 500, {text: 'All', value: -1}],
        rowsPerPageItems: [5, 10, 25, 50, 100, 250, 500, 1000],
        firstDayOfWeek: 1,
        experimentalFeatures: false
    },
    getters: {},
    mutations: {
        setLoading (state, payload) {
            state.loading = payload
        },
        setSnackbar (state, payload) {
            state.snackbar = payload
        },
        setExperimentalFeatures (state, payload) {
            state.experimentalFeatures = payload
        }
    },
    actions: {}
})

store.dispatch('cognito/init')

export default store
