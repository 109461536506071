import axios from 'axios'
import store from '@/plugins/store'
import router from '@/plugins/router'
import Amplify, { API } from 'aws-amplify'

API.configure()
const API_NAME = 'brisc'

const api = {
    get: function(endpoint, payload) {
        store.commit('app/setAppLoading', true)
        return API.get(API_NAME, endpoint, {
            queryStringParameters: payload ? payload.params : null
        }).then(successHandler).catch(errorHandler)
    },
    post: function(endpoint, payload) {
        store.commit('app/setAppLoading', true)
        return API.post(API_NAME, endpoint, {
            body: payload
        }).then(successHandler).catch(errorHandler)
    },
    put: function(endpoint, payload) {
        store.commit('app/setAppLoading', true)
        return API.put(API_NAME, endpoint, {
            body: payload
        }).then(successHandler).catch(errorHandler)
    },
    patch: function(endpoint, payload) {
        store.commit('app/setAppLoading', true)
        return API.patch(API_NAME, endpoint, {
            body: payload
        }).then(successHandler).catch(errorHandler)
    },
    delete: function(endpoint) {
        store.commit('app/setAppLoading', true)
        return API.del(API_NAME, endpoint)
        .then(successHandler).catch(errorHandler)
    }
}

function successHandler(response) {
    store.commit('app/setAppLoading', false)
    return {data: response}
}

function errorHandler(error) {
    store.commit('app/setAppLoading', false)
    if ( error.response ) {
        if ( error.response.status === 401 ) store.dispatch('cognito/signOut')
        if ( error.response.status === 404 ) router.push('/')
        if ( error.response.data instanceof Object ) {
            const errorKey = Object.keys(error.response.data)[0]
            store.commit('setSnackbar', { 
                type: 'error', 
                msg: `${error.response.statusText}: "${errorKey}" - ${error.response.data[errorKey]}`
            })                
        }
        else store.commit('setSnackbar', { type: 'error', msg: `${error.response.statusText}: ${error.message}` })
    } else {
        store.commit('setSnackbar', { type: 'error', msg: error.message })
    }
    return Promise.reject(error.response)
}

const apiGet = async (endpoint, params) => {
    store.commit('app/setAppLoading', true)
    try {
        let res = await API.get(API_NAME, endpoint, { queryStringParameters: params })
        store.commit('app/setAppLoading', false)
        return {
            data: res
        }
    }
    catch (error) {
        store.commit('app/setAppLoading', false)
        return error
    }
}

const apiPost = async (endpoint, data) => {
    store.commit('app/setAppLoading', true)
    try {
        let res = await API.post(API_NAME, endpoint, {
            body: data
        })
        store.commit('app/setAppLoading', false)
        return {
            data: res
        }
    }
    catch (error) {
        store.commit('app/setAppLoading', false)
        return error 
    }
}

const apiPut = async (endpoint, data) => {
    store.commit('app/setAppLoading', true)
    try {
        let res = await API.put(API_NAME, endpoint, {
            body: data
        })
        store.commit('app/setAppLoading', false)
        return {
            data: res
        }
    }
    catch (error) {
        store.commit('app/setAppLoading', false)
        return error 
    }
}

const apiPatch = async (endpoint, data) => {
    store.commit('app/setAppLoading', true)
    try {
        let res = await API.patch(API_NAME, endpoint, {
            body: data
        })
        store.commit('app/setAppLoading', false)
        return {
            data: res
        }
    }
    catch (error) { 
        store.commit('app/setAppLoading', false)
        return error 
    }
}

const apiDelete = async (endpoint) => {
    store.commit('app/setAppLoading', true)
    try {
        let res = await API.del(API_NAME, endpoint)
        store.commit('app/setAppLoading', false)
        return {
            data: res
        }
    }
    catch (error) { 
        store.commit('app/setAppLoading', false)
        return error 
    }
}

export default api

export { apiGet, apiPost, apiPut, apiPatch, apiDelete }