import api from '@/plugins/api'
import router from '@/plugins/router'
import names from '@/configs/app_names'

const state = {
    sites: null,
    sitesAll: null,
    siteById: null,
    ordering: [
        { text: 'Name (A-Z)', value: 'name' },
        { text: 'Name (Z-A)', value: '-name' }
    ],
    headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: `${names.locations.label.singular} Count`, align: 'left', sortable: false }
    ],
    pagination: {
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        page: 1,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    },
    query: {
        limit: 10,
        offset: 0,
        q: undefined,
        ordering: undefined,
        paging: undefined
    }
}

const getters = {
    sites ({ sites }) {
        return sites ? sites.data.results : []
    },
    sitesCount ({ sites }) {
        return sites ? sites.data.count : 0
    },
    sitesAll ({ sitesAll }) {
        return sitesAll ? sitesAll.data.results : []
    },
    siteById ({ siteById }) {
        return siteById ? siteById.data : {}
    }
}

const mutations = {
    setSites (state, payload) {
        state.sites = payload        
    },
    setSitesAll (state, payload) {
        state.sitesAll = payload        
    },
    setSiteById (state, payload) {
        state.siteById = payload
    },
    setPagination (state, payload) {
        state.pagination = payload        
    },
    // setPaginationTotalItems (state, payload) {
    //     state.pagination.totalItems = payload
    // },
    setPaginationDescending (state, payload) {
        state.pagination.sortDesc = payload        
    },
    setPaginationSortBy (state, payload) {
        state.pagination.sortBy = payload        
    },
    // setQuery (state, payload) {
    //     state.query = payload        
    // },
    setQueryLimit (state, payload) {
        state.query.limit = payload
    },
    setQueryOffset (state, payload) {
        state.query.offset = payload
    },
    setQuerySearch (state, payload) {
        state.query.q = payload
    },
    setQueryOrdering (state, payload) {
        state.query.ordering = payload
    },
    setQueryPaging (state, payload) {
        state.query.paging = payload
    }
}

const actions = {
    async getSites({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            commit('setSites', await api.get('/sites', payload))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getSitesWithInternalQuery({ commit }, query) {
        try {
            commit('setLoading', true, { root: true })
            commit('setSites', await api.get('/sites', { params: query }))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    setPagination({ commit }, payload) {
        commit('setPagination', payload)
        if ( payload.itemsPerPage> 0 ) {
            commit('setQueryLimit', payload.itemsPerPage)
            commit('setQueryOffset', (payload.page - 1) * payload.itemsPerPage)
            commit('setQueryPaging', undefined)
        } else {
            commit('setQueryLimit', undefined)
            commit('setQueryOffset', undefined)
            commit('setQueryPaging', 'none')
        }
        if ( payload.sortBy[0] ) {
            if ( payload.sortDesc[0] ) {                
                commit('setQueryOrdering', `-${payload.sortBy[0]}`)  
            } else {
                commit('setQueryOrdering', payload.sortBy[0])
            }
        } else {
            commit('setQueryOrdering', undefined)
        }
    },
    resetPagination({ state, commit, dispatch }) {
        dispatch('setPagination', {
            sortDesc: [],
            page: 1,
            itemsPerPage: state.pagination.itemsPerPage, 
            sortBy: [],
            // totalItems: 0
        })
        commit('setQuerySearch', undefined)
    },
    setOrdering({ commit }, payload) {
        commit('setQueryOrdering', payload)
        if ( payload ) {
            if ( payload.startsWith('-') ) {
                commit('setPaginationDescending', [true])
                commit('setPaginationSortBy', [payload.slice(1)])
            } else {
                commit('setPaginationDescending', [])
                commit('setPaginationSortBy', [payload])
            }
        } else {
            commit('setPaginationDescending', [])
            commit('setPaginationSortBy', [])
        }
    },
    async getLocalSites({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            return await api.get('/sites', payload)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getSitesAll({ commit }) {
        try {
            commit('setLoading', true, { root: true })
            commit('setSitesAll', await api.get('/sites?paging=none'))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getSiteById({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            commit('setSiteById', await api.get(`/sites/${id}`))                        
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async postSite({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            const res = await api.post('/sites', payload)
            router.replace(`/sites/${res.data.id}`)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async putSiteById({ commit, dispatch }, payload) {
        try {
            commit('setLoading', true, { root: true })
            const res = await api.put(`/sites/${payload.id}`, payload.formData)
            commit('setSites', await api.get('/sites'))
            dispatch('getSiteById', (res.data.id))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async deleteSiteById({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            await api.delete(`/sites/${id}`)
            router.replace('/sites')
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    } 
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
