<template>
    <v-app>
        <router-view name="headerSidebar" />
        <v-main class="background">
            <v-container class="pt-0 px-2" fluid>
                <v-row justify="center" :class="`pt-${$vuetify.breakpoint.xs ? 1 : 3}`" dense>
                    <v-col :style="style">
                        <v-fade-transition hide-on-leave>
                            <router-view />
                        </v-fade-transition>
                        <v-row class="text-caption tertiary-text--text px-1 pt-3" :justify="$vuetify.breakpoint.xsOnly || path.includes('/auth/') ? 'center' : 'space-between'">
                            <v-col cols="auto" class="py-0">
                                <small>brisc campaign manager - {{ environment }} - {{ productVersion }}</small>
                            </v-col>
                            <v-col cols="auto" class="py-0">
                                <small>Copyright &copy; 2022, TechMedia Digital Systems Pty Ltd</small>
                                <small class="ml-2"><a href="https://www.techmedia.com.au/tmeula" target="_blank">EULA</a></small>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <app-snack-bar />
    </v-app>
</template>

<script>
import { mapState } from 'vuex'
import { Hub } from 'aws-amplify'

export default {
    name: 'App',
    components: {
        AppSnackBar: () => import('@/components/app/AppSnackBar')
    },
    computed: {
        ...mapState(['productVersion']),
        ...mapState('route', ['path']),
        style () {
            return {
                'max-width': this.path.includes('/auth/') ? '500px' : '1280px'
            }
        },
        environment () {
            return process.env.VUE_APP_MODE
        }
    },
    mounted() {
        Hub.listen('auth', (data) => {
            if (data.payload.event === 'signIn') {
                this.$router.push('/')
            }
        })
    }
}
</script>

<style>
.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cursor-link {
    cursor: pointer;
}

.v-toolbar__content {
    padding: 0 !important;
}
</style>
