import api from '@/plugins/api'
import router from '@/plugins/router'
import names from '@/configs/app_names'

const state = {
    media: null,
    mediaById: null,
    mediaForBrowserData: null,
    filters: {
        mediaType: [
            { text: 'Image', value: 'IMAGE' },
            { text: 'Video', value: 'VIDEO' },
            { text: 'Audio', value: 'AUDIO' },
            { text: 'Scala Script', value: 'SCALA_SCRIPT' },
            { text: 'Widget', value: 'WIDGET' }
        ]
    },
    ordering: [
        { text: 'Updated (First)', value: '-updated' },
        { text: 'Updated (Last)', value: 'updated' },
        { text: 'Name (A-Z)', value: 'name' },
        { text: 'Name (Z-A)', value: '-name' },
        { text: 'Created (First)', value: 'created' },
        { text: 'Created (Last)', value: '-created' },
        { text: 'Type (A-Z)', value: 'media_type' },
        { text: 'Type (Z-A)', value: '-media_type' },
        { text: 'File Size (Small)', value: 'file_size' },
        { text: 'File Size (Large)', value: '-file_size' }
    ],
    headers: [
        { text: 'Thumbnail', sortable: false },
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Type', value: 'media_type', class: 'hidden-sm-and-down' },
        { text: 'Dimensions', sortable: false, class: 'hidden-sm-and-down' },
        { text: 'Duration', sortable: false, class: 'hidden-sm-and-down' },
        { text: 'Size', value: 'file_size', class: 'hidden-sm-and-down' },
        { text: '', align: 'left', sortable: false, class: 'hidden-md-and-up' }
    ],
    pagination: {
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        page: 1,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    },
    query: {
        limit: 10,
        offset: 0,
        q: undefined,
        ordering: undefined,
        media_type: undefined,
        paging: undefined
    }
}

const getters = {
    media ({ media }) {
        return media ? media.data.results : []
    },
    mediaCount ({ media }) {
        return media ? media.data.count : 0
    },
    mediaById ({ mediaById }) {
        return mediaById ? mediaById.data : {}
    },
    mediaForBrowser ({ mediaForBrowserData }) {
        return mediaForBrowserData ? mediaForBrowserData.results : []
    },
    mediaForBrowserCount ({ mediaForBrowserData }) {
        return mediaForBrowserData ? mediaForBrowserData.count : 0
    }
}

const mutations = {
    setMedia (state, payload) {
        state.media = payload
    },
    setMediaById (state, payload) {
        state.mediaById = payload
    },
    setMediaForBrowserData (state, payload) {
        state.mediaForBrowserData = payload
    },
    setPagination (state, payload) {
        state.pagination = payload        
    },
    setPaginationDescending (state, payload) {
        state.pagination.sortDesc = payload        
    },
    setPaginationSortBy (state, payload) {
        state.pagination.sortBy = payload        
    },
    setQueryLimit (state, payload) {
        state.query.limit = payload
    },
    setQueryOffset (state, payload) {
        state.query.offset = payload
    },
    setQuerySearch (state, payload) {
        state.query.q = payload
    },
    setQueryOrdering (state, payload) {
        state.query.ordering = payload
    },
    setQueryMediaType (state, payload) {
        state.query.media_type = payload
    },
    setQueryPaging (state, payload) {
        state.query.paging = payload
    }
}

const actions = {
    async getMediaLocalNEW({ commit }, payload) {
        try {
            return await api.get(names.media.api, payload).then(response => response.data)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
            throw error
        }
    },


    async getMediaWithInternalQuery({ commit }, query) {
        try {
            commit('setLoading', true, { root: true })
            commit('setMedia', await api.get(names.media.api, { params: query }))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getMediaForBrowser({ commit }, query) {
        try {
            commit('setLoading', true, { root: true })
            const res = await api.get(names.media.api, { params: query })
            commit('setMediaForBrowserData', res.data)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    setPagination({ commit }, payload) {
        commit('setPagination', payload)
        if ( payload.itemsPerPage> 0 ) {
            commit('setQueryLimit', payload.itemsPerPage)
            commit('setQueryOffset', (payload.page - 1) * payload.itemsPerPage)
            commit('setQueryPaging', undefined)
        } else {
            commit('setQueryLimit', undefined)
            commit('setQueryOffset', undefined)
            commit('setQueryPaging', 'none')
        }
        if ( payload.sortBy[0] ) {
            if ( payload.sortDesc[0] ) {                
                commit('setQueryOrdering', `-${payload.sortBy[0]}`)  
            } else {
                commit('setQueryOrdering', payload.sortBy[0])
            }
        } else {
            commit('setQueryOrdering', undefined)
        }
    },
    resetPagination({ state, commit, dispatch }) {
        dispatch('setPagination', {
            sortDesc: [],
            page: 1,
            itemsPerPage: state.pagination.itemsPerPage, 
            sortBy: [],
            // totalItems: 0
        })
        commit('setQuerySearch', undefined)
    },
    setOrdering({ commit }, payload) {
        commit('setQueryOrdering', payload)
        if ( payload ) {
            if ( payload.startsWith('-') ) {
                commit('setPaginationDescending', [true])
                commit('setPaginationSortBy', [payload.slice(1)])
            } else {
                commit('setPaginationDescending', [])
                commit('setPaginationSortBy', [payload])
            }
        } else {
            commit('setPaginationDescending', [])
            commit('setPaginationSortBy', [])
        }
    },
    async getMedia({ commit, rootState }) {
        try {
            commit('setLoading', true, { root: true })
            commit('setMedia', await api.get(names.media.api, {
                params: rootState.route.query
            }))
            commit('resetMediaById')
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async getMediaById({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            commit('setMediaById', await api.get(`${names.media.api}/${id}`))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async deleteMediaById({ commit }, id) {
        try {
            commit('setLoading', true, { root: true })
            await api.delete(`${names.media.api}/${id}`)
            router.replace({ name: names.media.route })
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
