import names from '@/configs/app_names'

export default {
    CampaignCreativeSchedulers: {
        allowedWriteAccess: [
            'media',
            'media_instructions'
        ],
        allowedRoutes: [
            'CampaignsGroup',
            'Campaigns',
            'CampaignOverview',
            'CampaignBookingsList',
            'CampaignBookingDetails',
            'CampaignMediaSchedulesList',
            'CampaignMediaSchedulesCreate',
            'CampaignMediaSchedulesReadUpdateDelete',
            'MediaGroup',
            names.media.route,
            names.media.route + 'Instance' + 'Details'
        ]
    },
    CampaignViewers: {
        allowedWriteAccess: [],
        allowedRoutes: [
            'CampaignsGroup',
            'Campaigns',
            'CampaignOverview',
            'CampaignBookingsList',
            'CampaignBookingDetails',
            'CampaignMediaSchedulesList',
            'CampaignMediaSchedulesReadUpdateDelete',
            'CampaignReportsPlaceholder',

            'Bookings',
            'BookingDetails',

            'MediaGroup',
            names.media.route,
            names.media.route + 'Instance' + 'Details',

            'LoopsGroup',
            'Loops',
            'LoopsList',
            'LoopDetails'
        ]
    },
    CampaignManagers: {
        allowedWriteAccess: [
            'campaigns',
            'bookings',
            'media',
            'media_instructions',
            'loops'
        ],
        allowedRoutes: [
            'CampaignsGroup',
            'Campaigns',
            'CampaignOverview',
            'CampaignBookingsList',
            'CampaignBookingsAdd',
            'CampaignBookingDetails',
            'CampaignMediaSchedulesList',
            'CampaignMediaSchedulesCreate',
            'CampaignMediaSchedulesReadUpdateDelete',
            'CampaignReportsPlaceholder',

            'Bookings',
            'BookingDetails',

            'MediaGroup',
            names.media.route,
            names.media.route + 'Instance' + 'Details',

            'LoopsGroup',
            'Loops',
            'LoopsList',
            'LoopDetails'
        ]
    },
    Administrators: {
        allowedWriteAccess: [
            'campaigns',
            'bookings',
            'media',
            'media_instructions',
            'loops'
        ],
        allowedRoutes: [            
            'CampaignsGroup',
            'Campaigns',
            // 'CampaignById', // pass through
            'CampaignOverview',
            'CampaignBookingsList',
            'CampaignBookingsAdd',
            'CampaignBookingDetails',
            'CampaignMediaSchedulesList',
            'CampaignMediaSchedulesCreate',
            'CampaignMediaSchedulesReadUpdateDelete',
            'CampaignReportsPlaceholder',
            // 'PDF',

            'Bookings',
            // 'BookingById', // pass through
            'BookingDetails',

            'CampaignCategories',

            'MediaGroup',
            names.media.route,
            // names.media.route + 'Instance', // pass through
            names.media.route + 'Instance' + 'Details',
            'DynamicMediaList',
            'ReactiveMediaById',
            'ReactiveMediaCreate',

            'LoopsGroup',
            'Loops',
            'LoopsList',
            // 'LoopById', // pass through
            'LoopDetails',

            'LoopPolicies',
            'LoopPoliciesAdd',
            // 'LoopPolicyById', // pass through
            'LoopPolicyDetails',
            'LoopPolicyEdit',

            'LocationsGroup',
            names.locations.routeGroup,
            names.locations.route,
            // names.locations.route + 'Instance', // pass through
            names.locations.route + 'Instance' + 'Details',
            names.locations.route + 'Instance' + 'Loops',
            names.locations.route + 'Instance' + 'Loops' + 'Instance',
            
            'Attributes',
            'AttributeById',
            'AttributeValuesList',

            'ContentSpecifications',
            'ContentSpecificationById',

            'OperatingHoursPolicies',
            // 'OperatingHoursPolicyById', // pass through
            'OperatingHoursPolicyDetails',

            'SitesGroup',
            'Sites',
            // 'SiteById', // pass through
            'SiteDetails',
            'SiteLocationsList'
        ]
    }
}