<template>
    <div>    
        <v-app-bar 
            app 
            color="white"
            dense
            elevation="2"
            clipped-left
        >
            <v-row dense align="center" justify="space-between">
                <v-col class="pl-4" cols="auto">
                    <v-sheet v-if="$vuetify.breakpoint.xs" width="28" color="yellow"></v-sheet>
                    <v-btn v-else small icon @click.stop="drawer = !drawer">
                        <v-icon color="secondary-text">mdi-menu</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn text rounded class="text-capitalize" to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600" style="width: 1.3rem; margin-right: 0.124rem">
                            <path class="primary--text" fill="currentColor" d="M900,600c0,165.69-134.31,300-300,300S300,765.69,300,600V300H600C765.69,300,900,434.31,900,600Z" transform="translate(-300 -300)"  />
                            <circle fill="#FFF" cx="300" cy="300" r="200" />
                            <rect fill="#FFF" x="100" y="120" width="100" height="180" />
                            <circle fill="#FFF" cx="150" cy="120" r="50" />
                            <circle class="primary--text" fill="currentColor" cx="300" cy="300" r="100"  />
                        </svg>
                        <span class="text-h6 pr-1 primary-text--text text-lowercase">brisc</span>
                    </v-btn>
                </v-col>
                <v-col class="pr-4" cols="auto">
                    <v-menu v-if="$vuetify.breakpoint.smAndUp" nudge-bottom=42 min-width="200" max-width="300">
                        <template v-slot:activator="{ on }">
                            <v-btn small icon v-on="on" class="">
                                <v-icon color="secondary-text">mdi-account-circle</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense class="px-2">
                            <div class="text-caption text-center tertiary-text--text my-1">Username</div>
                            <div class="text-body-2 text-center grey lighten-4 px-2 py-1" style="word-break: break-all; border-radius: 5px">{{ username }}</div>
                            <div class="text-caption text-center tertiary-text--text mt-3">Role</div>
                            <div class="text-body-2 text-center grey lighten-4 px-2 py-1" style="word-break: break-word; border-radius: 5px">{{ userGroup || 'No Role Assigned' }}</div>
                            <v-list-item class="my-2">
                                <v-btn 
                                    class="mx-auto text-capitalize white--text button-accept" 
                                    small depressed
                                    @click="signOut"
                                >
                                    Sign out
                                </v-btn>
                            </v-list-item>
                            <!-- <v-divider></v-divider>
                            <v-list-item>
                                <v-checkbox 
                                    v-model="experimentalFeatures"
                                    class="mt-0"
                                    small 
                                    color="grey" 
                                    label="Experimental Features"
                                    hide-details
                                    @click.native.stop="" 
                                />
                            </v-list-item> -->
                        </v-list>
                    </v-menu>
                    <v-btn v-if="$vuetify.breakpoint.xs" small icon @click.stop="drawer = !drawer">
                        <v-icon color="secondary-text">mdi-menu</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-app-bar>
        <v-navigation-drawer
            app
            v-model="drawer"
            :width="$vuetify.breakpoint.xs ? '100%' : '200'"
            :mini-variant="mini"
            mini-variant-width="58"
            clipped
            color="navDrawer"
        >
            <div v-for="(item, i) in sideMenuItems" :key="item.name">
                <v-list nav dense v-if="$store.getters['cognito/userGroupAllowedRoutes'].includes(item.route)">
                    <v-subheader v-if="!mini" class="text-uppercase navDrawer--text text--lighten-3 mb-n2">{{ item.name }}</v-subheader>
                    <v-tooltip
                        v-for="subItem in item.children" :key="subItem.name"
                        right
                        :disabled="!mini"
                    >
                        <template v-slot:activator="{ on }">
                            <v-list-item
                                v-if="$store.getters['cognito/userGroupAllowedRoutes'].includes(subItem.route)"
                                color="primary"
                                v-on="on"
                            >
                                <v-list-item
                                    :to="{ name: subItem.route }"
                                    @click="reset"
                                    active-class="primary"
                                >
                                <v-list-item-icon :class="mini ? 'px-1' : 'mr-3'">
                                    <v-icon class="navText--text" size="18">{{ subItem.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title 
                                            :class="[
                                                `${path.includes(subItem.path) ? 'white--text font-weight-medium' : 'navText--text font-weight-regular'}`, 
                                                `${$vuetify.breakpoint.xs ? 'text-subtitle-1' : 'text-subtitle-2'}`
                                            ]"
                                        >
                                            {{ subItem.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item>
                        </template>
                        <span class="text-caption">{{ subItem.name }}</span>
                    </v-tooltip>
                </v-list>
                <v-divider v-if="i + 1 !== sideMenuItems.length" class="navDrawer lighten-2 mx-2"></v-divider>
            </div>
            <template v-slot:prepend v-if="$vuetify.breakpoint.xs">
                <v-app-bar color="white" flat dense>
                    <v-row dense align="center" justify="space-between">
                        <v-col class="pl-4" cols="auto">
                            <v-sheet width="28" color="yellow"></v-sheet>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn text rounded class="text-capitalize" to="/">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600" style="width: 1.3rem; margin-right: 0.124rem">
                                    <path class="primary--text" fill="currentColor" d="M900,600c0,165.69-134.31,300-300,300S300,765.69,300,600V300H600C765.69,300,900,434.31,900,600Z" transform="translate(-300 -300)"  />
                                    <circle fill="#FFF" cx="300" cy="300" r="200" />
                                    <rect fill="#FFF" x="100" y="120" width="100" height="180" />
                                    <circle fill="#FFF" cx="150" cy="120" r="50" />
                                    <circle class="primary--text" fill="currentColor" cx="300" cy="300" r="100"  />
                                </svg>
                                <span class="text-h6 pr-1 primary-text--text text-lowercase">brisc</span>
                                <!-- <span class="hidden-xs-only font-weight-light primary-text--text">Campaign Manager</span> -->
                            </v-btn>
                        </v-col>
                        <v-col class="pr-4" cols="auto">
                            <v-btn small icon @click.stop="drawer = !drawer">
                                <v-icon color="secondary-text">mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-app-bar>
            </template>
            <template v-slot:append>
                <v-divider class="navDrawer lighten-2"></v-divider>
                <v-list nav dense>
                    <v-list-item
                        v-if="$vuetify.breakpoint.smAndUp"
                        @click="mini = !mini"
                    >
                        <v-list-item-icon>
                            <v-icon color="navText">{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-subtitle-2 navText--text font-weight-regular">Collapse</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="$vuetify.breakpoint.xs">
                        <v-list-item-content>
                            <v-list-item-title class="text-caption text-center navText--text font-weight-regular">
                                {{ username }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-btn
                        v-if="$vuetify.breakpoint.xs"
                        block
                        class="mx-auto text-capitalize white--text button-accept" 
                        small depressed
                        @click="signOut"
                    >
                        Sign out
                    </v-btn>
                </v-list>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, } from 'vuex'

export default {
    data () {
        return {
            drawer: null,
            mini: false
        }
    },
    computed: {
        ...mapState('route', ['path', 'name']),
        ...mapGetters('app', ['menuItems', 'sideMenuItems']),
        ...mapGetters('cognito', ['username', 'userGroup']),
        experimentalFeatures: {
            get () { return this.$store.state.experimentalFeatures },
            set (value) { this.$store.commit('setExperimentalFeatures', value) }
        }
    },
    methods: {
        ...mapActions('cognito', ['signOut']),
        ...mapActions('campaigns', { resetPaginationCampaigns: 'resetPagination' }),
        ...mapActions('bookings', { resetPaginationBookings: 'resetPagination' }),
        ...mapActions('media', { resetPaginationMedia: 'resetPagination' }),
        ...mapActions('loops', { resetPaginationLoops: 'resetPagination' }),
        ...mapActions('locations', { resetPaginationLocations: 'resetPagination' }),
        ...mapActions('sites', { resetPaginationSites: 'resetPagination' }),
        ...mapActions('loopPolicies', { resetPaginationLoopPolicies: 'resetPagination' }),
        reset () {
            this.resetPaginationCampaigns()
            this.resetPaginationBookings()
            this.resetPaginationMedia()
            this.resetPaginationLoops()
            this.resetPaginationLocations()
            this.resetPaginationSites()
            this.resetPaginationLoopPolicies()
        }
    },
    watch: {
        '$vuetify.breakpoint.xs' (to) {
            this.mini = to ? false : this.mini
        }
    }
}
</script>
