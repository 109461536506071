import api from '@/plugins/api'
import { cloneDeep } from 'lodash'
import names from '@/configs/app_names'

const nowAsLocalISOString = () => {
    const localTZOffset = new Date().getTimezoneOffset() * 60000
    return new Date(Date.now() - localTZOffset).toISOString().slice(0, -1)
}
const now = nowAsLocalISOString()

const state = {
    loops: null,
    ordering: [
        { text: 'Start (asc)', value: 'start_datetime' },
        { text: 'Start (dec)', value: '-start_datetime' },
        { text: 'End (asc)', value: 'end_datetime' },
        { text: 'End (dec)', value: '-end_datetime' },
        { text: `${names.locations.label.singular} (A-Z)`, value: 'location__name' },
        { text: `${names.locations.label.singular} (Z-A)`, value: '-location__name' }
    ],
    headers: [
        { text: names.locations.label.singular, align: 'left', value: 'location__name' },
        { text: 'Status', align: 'left', sortable: false, class: 'hidden-sm-and-down' },
        // { text: 'Booking ID', align: 'left', sortable: false },
        { text: 'Start', align: 'left', value: 'start_datetime', class: 'hidden-sm-and-down' },
        { text: 'End', align: 'left', value: 'end_datetime', class: 'hidden-sm-and-down' },
        { text: 'Range', align: 'left', sortable: false, class: 'hidden-xs-only hidden-md-and-up' },
        // { text: 'Campaign', align: 'left', sortable: false, class: 'hidden-sm-and-down' },
        { text: 'Occupancy', align: 'left', sortable: false, class: 'hidden-sm-and-down' },
        { text: '', align: 'left', sortable: false }
    ],
    pagination: {
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        page: 1,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    },
    query: {
        limit: 10,
        offset: 0,
        q: undefined,
        ordering: undefined,
        paging: undefined,        
        location: undefined,        
        start_datetime__lt: undefined,
        start_datetime__lte: now,
        start_datetime__gt: undefined,
        start_datetime__gte: undefined,
        end_datetime__lt: undefined,
        end_datetime__lte: undefined,
        end_datetime__gt: undefined,
        end_datetime__gte: now
    },
    queryFilterKeys: [
        'location',
        'start_datetime__lt',
        'start_datetime__lte',
        'start_datetime__gt',
        'start_datetime__gte',
        'end_datetime__lt',
        'end_datetime__lte',
        'end_datetime__gt',
        'end_datetime__gte',
    ],
    loopById: null,
    loopByIdItemsSortable: null
}

const getters = {
    loops ({ loops }) {
        return loops ? loops.data.results : []
    },
    loopsCount ({ loops }) {
        return loops ? loops.data.count : 0
    },
    loopById ({ loopById }) {
        return loopById ? loopById.data : null
    },
    loopByIdItemsSortable ({ loopByIdItemsSortable }) {
        return loopByIdItemsSortable
    },
    queryFiltersActive ({ query, queryFilterKeys }) {
        return queryFilterKeys.some(x => query[x])
    }
}

const mutations = {
    setLoops ( state, payload ) {
        state.loops = payload
    },
    setLoopById (state, payload) {
        state.loopById = payload
    },
    setLoopByIdItemsSortable (state, payload) {
        state.loopByIdItemsSortable = payload
    },
    setPagination (state, payload) {
        state.pagination = payload        
    },
    setPaginationDescending (state, payload) {
        state.pagination.sortDesc = payload        
    },
    setPaginationSortBy (state, payload) {
        state.pagination.sortBy = payload        
    },
    setQueryLimit (state, payload) {
        state.query.limit = payload
    },
    setQueryOffset (state, payload) {
        state.query.offset = payload
    },
    setQuerySearch (state, payload) {
        state.query.q = payload
    },
    setQueryOrdering (state, payload) {
        state.query.ordering = payload
    },
    setQueryPaging (state, payload) {
        state.query.paging = payload
    },
    setQueryProperty (state, { property, value }) {
        state.query[property] = value
    }
}

const actions = {
    async getLoops({ commit }, payload) { //update all stores with generic get actions
        try {
            commit('setLoading', true, { root: true })
            commit('setLoops',  await api.get('/playlists', payload))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    setPagination({ commit }, payload) {
        commit('setPagination', payload)
        if ( payload.itemsPerPage> 0 ) {
            commit('setQueryLimit', payload.itemsPerPage)
            commit('setQueryOffset', (payload.page - 1) * payload.itemsPerPage)
            commit('setQueryPaging', undefined)
        } else {
            commit('setQueryLimit', undefined)
            commit('setQueryOffset', undefined)
            commit('setQueryPaging', 'none')
        }
        if ( payload.sortBy[0] ) {
            if ( payload.sortDesc[0] ) {                
                commit('setQueryOrdering', `-${payload.sortBy[0]}`)  
            } else {
                commit('setQueryOrdering', payload.sortBy[0])
            }
        } else {
            commit('setQueryOrdering', undefined)
        }
    },
    resetPagination({ state, commit, dispatch }) {
        dispatch('setPagination', {
            sortDesc: [],
            page: 1,
            itemsPerPage: state.pagination.itemsPerPage, 
            sortBy: [],
            // totalItems: 0
        })
        commit('setQuerySearch', undefined)
        state.queryFilterKeys.forEach( x => {
            if ( x === 'start_datetime__lte' || x === 'end_datetime__gte' ) commit('setQueryProperty', { property: x, value: now })
            else commit('setQueryProperty', { property: x, value: undefined })
        })
    },
    setOrdering({ commit }, payload) {
        commit('setQueryOrdering', payload)
        if ( payload ) {
            if ( payload.startsWith('-') ) {
                commit('setPaginationDescending', [true])
                commit('setPaginationSortBy', [payload.slice(1)])
            } else {
                commit('setPaginationDescending', [])
                commit('setPaginationSortBy', [payload])
            }
        } else {
            commit('setPaginationDescending', [])
            commit('setPaginationSortBy', [])
        }
    },
    async getLoopById({ commit, getters }, id) {
        try {
            commit('setLoading', true, { root: true })
            commit('setLoopById', await api.get(`/playlists/${id}`))
            commit('setLoopByIdItemsSortable', cloneDeep(getters.loopById.items))
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async patchLoopItem({ commit }, payload) {
        try {
            commit('setLoading', true, { root: true })
            await api.patch(`/playlistitems/${payload.loopItemId}`, payload.body)
        } catch(error) {
            commit('setSnackbar', { type: 'error', msg: error.statusText ? error.statusText : error }, { root: true })
        } finally {
            commit('setLoading', false, { root: true })
        }
    },
    async patchLoopItemIterator({ dispatch }, payload) {
        for ( var item of payload.body ) {
            await dispatch('patchLoopItem', item)
        }
        dispatch('getLoopById', payload.loopId)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
