import Vue from 'vue'
import App from '@/App.vue'
import router from '@/plugins/router'
import store from '@/plugins/store'
import { sync } from 'vuex-router-sync'
import vuetify from '@/plugins/vuetify'
import Amplify from '@aws-amplify/core'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development' ? true : false

sync(store, router)

Amplify.configure({ 
    Auth: {
        userPoolId: process.env.VUE_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,
        identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
        region: process.env.VUE_APP_REGION,
        oauth: {
            domain: process.env.VUE_APP_USER_POOL_DOMAIN,
            scope: JSON.parse(process.env.VUE_APP_USER_POOL_SCOPE),
            redirectSignIn: process.env.VUE_APP_REDIRECT_SIGNIN,
            redirectSignOut: process.env.VUE_APP_REDIRECT_SIGNOUT,
            responseType: 'code'
          }
    },
    API: {
        endpoints: [
            {
                name: 'brisc',
                endpoint: process.env.VUE_APP_API,
                region: process.env.VUE_APP_REGION
            }
        ]
    }
})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
