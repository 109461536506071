import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#6366F1',
                secondary: '#A5B4FC',
                background: '#ECEEF1',
                header: '#eee',

                // 'panel-light': '#fafafa',
                // 'panel-medium': '#f0f0f0',
                // 'panel-dark': '#eee',

                'panel-light': '#F9FAFB',
                'panel-medium': '#E2E8F0',
                'panel-dark': '#94A3B8',

                'primary-text': '#374151',
                'secondary-text': '#6B7280',
                'tertiary-text': '#96999e',


                // 'button-default': '#808080',
                // 'button-accept': '#709e7c',
                // 'button-delete': '#222',
                // 'secondary-text': '#666',

                'button-default': '#808080',
                'button-accept': '#6366F1',
                'button-delete': '#EF4444',

                // success: '#4caf50',
                // info: '#2c8ffa',
                // warning: '#cf7c2c',
                // error: '#f84e4e',
                // draft_status: '#c0c0c0',
                // upcoming_status: '#f8cb6a',

                active_status: '#34D399',
                expired_status: '#c46868',
                cancelled_status: '#e299df',
                amber_status: '#FBBF24',
                error_status: '#EF4444',
                draft_status: '#c0c0c0',
                upcoming_status: '#e299df',
                complete_status: '#c46868',

                // active_status: '#5bd49c',
                // expired_status: '#c46868',
                // cancelled_status: '#e299df',
                // amber_status: '#FFB333',
                // error_status: '#f84e4e',

                

                navDrawer: '#312E81',
                navHeaderText: '#818CD8',
                navText: '#EEF2FF',
                navDivider: '#155E75',

                // progress_active: '#e299df',
                // progress_complete: '#5bd49c',
                // progress_error: '#f84e4e'
                progress_active: '#e299df',
                progress_complete: '#5bd49c',
                progress_error: '#EF4444'
            }
        }
    }
}

export default new Vuetify(opts)